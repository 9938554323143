import classNames from "classnames";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[direction]
import styles from "./Triangle.module.scss";

type TriangleProps = {
  direction?: "down" | "left" | "right" | "up";
};

export const Triangle = ({ direction = "up" }: TriangleProps) => {
  return <div className={classNames(styles.triangle, styles[direction])} />;
};
