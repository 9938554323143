import { useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import { colorCommonRed500, colorCommonGreen500, colorCommonGrey200 } from "@simplicate-software/design-tokens";

type SalesStatusTagProps = {
  statusId?: string;
};

const SALES_STATUS_ACCEPTED_ID = "salesstatus:dd17d506c62571b3";
const SALES_STATUS_DECLINED_ID = "salesstatus:ecc9fef9cdba8ee1";

export const SalesStatusTag = ({ statusId }: SalesStatusTagProps) => {
  const { t } = useTranslation("top_bar");

  const acceptedStatusId = statusId === SALES_STATUS_ACCEPTED_ID;
  const declinedStatusId = statusId === SALES_STATUS_DECLINED_ID;
  // eslint-disable-next-line sonarjs/no-nested-conditional -- disable for now, will be refactored
  const color = acceptedStatusId ? colorCommonGreen500 : declinedStatusId ? colorCommonRed500 : colorCommonGrey200;

  // eslint-disable-next-line sonarjs/no-nested-conditional -- disable for now, will be refactored
  const statusTitle = acceptedStatusId ? t("accepted_sales") : declinedStatusId ? t("declined_sales") : t("open_sales");

  return <Tag text={statusTitle} color={color} testId="status-tag" size="small" variant="color" />;
};
