import { RenderParams } from "@minoru/react-dnd-treeview";
import { InvoiceMethod, SubscriptionCycle } from "@simplicate/api-client";
import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Clickable, Icon, Tag } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { useState, useMemo, forwardRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ServiceNodeModel } from "../../GroupedServicesManager.types";
import { LoadingAnimation } from "../LoadingAnimation";
import { ServiceRowCell } from "../ServiceRowCell";
import styles from "./ServiceRow.module.scss";

export type ServiceRowProps = Partial<RenderParams> & {
  node: ServiceNodeModel;
};

// eslint-disable-next-line complexity -- todo: refactor
export const ServiceRow = forwardRef<HTMLSpanElement, ServiceRowProps>(function ServiceRow(
  { node: { id: serviceId, text, data } },
  handleRef,
) {
  const { t } = useTranslation("grouped_services_manager");
  const { id: salesId } = useParams();

  const [selectedServiceId, setSelectedServiceId] = useState<number | string>("");

  const loadingText = useMemo<string>(() => {
    if (data?.isLoading) {
      return data?.loadingText ?? "";
    } else {
      setSelectedServiceId("");

      return "";
    }
  }, [data?.isLoading, data?.loadingText]);

  const getInvoiceMethodLabel = (invoiceMethod: InvoiceMethod, subscriptionCycle?: SubscriptionCycle) => {
    switch (invoiceMethod) {
      case InvoiceMethod.fixed_price:
        return t("invoice_method.fixed");
      case InvoiceMethod.time_and_expenses:
        return t("invoice_method.hours");
      case InvoiceMethod.subscription:
        switch (subscriptionCycle) {
          case "monthly":
            return t("invoice_method.subscription_month");
          case "quarterly":
            return t("invoice_method.subscription_quarter");
          case "half_yearly":
            return t("invoice_method.subscription_halve_year");
          case "yearly":
            return t("invoice_method.subscription_year");
          default:
            return "";
        }
    }
  };

  const handleServiceAction = useCallback(
    (callback?: () => void): void => {
      if (callback) {
        setSelectedServiceId(serviceId);
        callback();
      }
    },
    [serviceId],
  );

  if (!data) return null;

  const {
    invoiceMethod,
    subscriptionCycle,
    hoursTotalAmount,
    hoursTotalBudget,
    purchaseTotalBudget,
    fixedPrice,
    totalPrice,
    duplicateCallback,
    deleteCallback,
    editCallback,
  } = data;

  const invoiceMethodLabel = getInvoiceMethodLabel(invoiceMethod, subscriptionCycle);

  return (
    <div className={styles.serviceRow} data-testid={`service-row-${serviceId}`}>
      {loadingText && selectedServiceId === serviceId ? <LoadingAnimation text={loadingText} type="row" /> : null}
      <ServiceRowCell variant="grip">
        <span ref={handleRef}>
          <Icon icon="grip" />
        </span>
      </ServiceRowCell>
      <ServiceRowCell variant="name">
        <Clickable to={`/sales/${salesId}/service/${serviceId}`}>{text}</Clickable>
        {invoiceMethod && invoiceMethodLabel && (
          <Tag
            size="small"
            text={invoiceMethodLabel}
            variant="light"
            testId={`service-row-${serviceId}-invoice-method-badge`}
          />
        )}
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        {hoursTotalAmount && hoursTotalAmount > 0 && <NumberFormat value={hoursTotalAmount} />}
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={hoursTotalBudget?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="number">
        <CurrencyFormat value={purchaseTotalBudget?.amount?.toString()} />
      </ServiceRowCell>
      {fixedPrice ? (
        <ServiceRowCell variant="number">
          {fixedPrice.quantity !== 1.0 && `${fixedPrice.quantity} × `}
          <CurrencyFormat value={fixedPrice.price.amount.toString()} />
        </ServiceRowCell>
      ) : (
        <ServiceRowCell variant="number"></ServiceRowCell>
      )}
      <ServiceRowCell variant="number">
        <CurrencyFormat value={totalPrice?.amount?.toString()} />
      </ServiceRowCell>
      <ServiceRowCell variant="button">
        <ActionDropdown testId="dropdown-menu" ariaLabel={t("actions")}>
          <ActionDropdown.Action onClick={() => handleServiceAction(duplicateCallback)}>
            <Icon icon="copy" />
            {t("duplicate")}
          </ActionDropdown.Action>
          <ActionDropdown.Action onClick={editCallback}>
            <Icon icon="pen" />
            {t("edit")}
          </ActionDropdown.Action>
          <ActionDropdown.Action onClick={() => handleServiceAction(deleteCallback)} className={styles.deleteButton}>
            <Icon icon="trash" color={lightColorIconError} />
            {t("delete")}
          </ActionDropdown.Action>
        </ActionDropdown>
      </ServiceRowCell>
    </div>
  );
});
