import classNames from "classnames";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import { SelectItem } from "primereact/selectitem";
import { Counter } from "../Counter";
import { Icon } from "../Icon";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[size]
import styles from "./SelectMulti.module.scss";

type SelectOption<T> = Omit<SelectItem, "className" | "value"> & {
  value: T;
  label: string;
};

type SelectMultiProps<T> = Omit<MultiSelectProps, "className" | "onChange" | "options" | "pt" | "size" | "value"> & {
  testId?: string;
  label?: string;
  value?: T[];
  options?: SelectOption<T>[];
  onChange: (value: T[]) => void;
  invalid?: boolean;
  size?: "default" | "small";
  showSelectedCount?: boolean;
  variantPanelStyle?: string;
};

export const SelectMulti = <T,>({
  testId,
  label,
  value = [],
  options = [],
  onChange,
  invalid,
  name,
  placeholder,
  size = "default",
  showSelectedCount = false,
  variantPanelStyle,
  ...multiSelectProps
}: SelectMultiProps<T>) => {
  const defaultProps: MultiSelectProps = {
    itemTemplate: (option: SelectOption<T>) => (
      <div className={styles.dropdownItemContent}>
        {option.label}
        {value.some((val) => val === option.value) && (
          <Icon testId={`${testId}-selectedIcon`} icon="check" className={styles.selectedIcon} />
        )}
      </div>
    ),
    filter: true,
    filterBy: "label",
    filterInputAutoFocus: true,
    resetFilterOnHide: true,
    showSelectAll: false,
    pt: {
      root: (options) => ({
        className: classNames(
          styles.dropdownRoot,
          styles.inputWrapper,
          styles.input,
          /* istanbul ignore next */
          options?.props.disabled ? styles.disabled : undefined,
        ),
      }),
      filterInput: () => ({
        className: classNames(styles.dropdownItem),
      }),
      filterContainer: () => ({
        className: classNames(styles.filterContainer),
      }),
      filterIcon: () => ({
        className: styles.filterIcon,
      }),
      header: () => ({
        className: styles.dropdownHeader,
      }),
      wrapper: () => ({
        className: styles.dropdownWrapper,
      }),
      item: () => ({
        className: styles.dropdownItem,
      }),
      list: () => ({
        className: styles.itemList,
      }),
      closeButton: () => ({
        className: styles.closeButton,
      }),
    },
  };

  return (
    <>
      <label htmlFor={name} className={styles.label} data-active={value.length > 0}>
        {label}
        <MultiSelect
          {...defaultProps}
          data-testid={testId}
          value={value}
          fixedPlaceholder={showSelectedCount}
          onChange={(event: { value: T[] }) => onChange(event.value)}
          options={options}
          onKeyDown={(event) => {
            /* istanbul ignore next */
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
              document.querySelector("[data-p-focused=true]")?.scrollIntoView(true);
            }
          }}
          // @ts-expect-error -- We need to pass the Counter component as a placeholder
          placeholder={
            showSelectedCount ? (
              <span className={classNames(styles.counterSpacing)}>
                {placeholder}
                {showSelectedCount && <Counter value={value.length} renderHiddenPlaceholder={true} />}
              </span>
            ) : (
              placeholder
            )
          }
          name={name}
          panelClassName={variantPanelStyle}
          className={classNames(
            invalid ? styles.invalid : undefined,
            styles[size],
            value.length > 0 && styles.activeControl,
          )}
          {...multiSelectProps}
        />
      </label>
    </>
  );
};
