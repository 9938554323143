import { Navigate, useParams } from "react-router-dom";
import { IFrameEnvironment } from "../IFrameEnvironment";
import { useShowTimetable } from "./useShowTimetable";

export const Timetable = () => {
  const { id } = useParams<{ id: string }>();
  const showTimeTable = useShowTimetable(id);

  return showTimeTable ? (
    <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=timetable" />
  ) : (
    <Navigate to="/403" replace />
  );
};
