import { useLocation } from "react-router-dom";

type RemoveSegmentsBackButtonBehavior = (amountOfSegements?: number) => {
  target: URL;
};

export const RemoveSegmentsBackButtonBehavior: RemoveSegmentsBackButtonBehavior = (amountOfSegments = 1) => {
  const { pathname } = useLocation();

  const pathnameParts = [];

  for (const part of pathname.split("/")) {
    pathnameParts.push(part);
  }
  for (let i = 0; i < amountOfSegments; i++) {
    pathnameParts.pop();
  }

  return {
    target: new URL(pathnameParts.join("/"), window.location.origin),
  };
};
