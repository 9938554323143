import { TFunction, useTranslation } from "@simplicate/translations";
import {
  Button,
  GroupedControls,
  Tag,
  TagList,
  Icon,
  usePortalContext,
  useIntersectionObserver,
  Portal,
  PAGE_STICKY_TOP,
  DateRangePicker,
} from "@simplicate/ui";
import { format } from "date-fns";
import { PropsWithChildren, useMemo, useRef } from "react";
import { useDashboardContext } from "../../components/Dashboard";
import { DimensionValueSelect } from "../../components/DimensionValueSelect";
import { Filter, CubeDimension, cubeDimensionToKey } from "../../types";
import styles from "./FilterWidget.module.scss";

const INTERSECTION_OBSERVER_OPTIONS = {
  // "out of view" when the element is entirely outside the observed box.
  threshold: 0,

  // Top UI (3rem) + height of itself (80px) = 128px
  // Negative values "lower" the observed boundary the element needs to cross to be out of view
  margin: "-128px",
};

export type DimensionFilterConfig = {
  valueDimension: CubeDimension;
  labelDimension: CubeDimension;
  placeholder?: (t: TFunction) => string;
  filterFormat?: (value: unknown, t: TFunction) => string;
};

type FilterWidgetProps = {
  dimensions: DimensionFilterConfig[];
};

const StickyContainer = ({ children }: PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { targetRefs } = usePortalContext();
  const { inView } = useIntersectionObserver(containerRef.current, INTERSECTION_OBSERVER_OPTIONS);

  return (
    <>
      <div ref={containerRef}>{children}</div>
      {!inView && (
        <Portal targetRef={targetRefs[PAGE_STICKY_TOP]}>
          <div>{children}</div>
        </Portal>
      )}
    </>
  );
};

const AppliedFilter = ({
  label,
  valueDimension,
  onRemove,
  dimensions: config,
}: Filter & FilterWidgetProps & { onRemove: () => void }) => {
  const { t } = useTranslation("insights");
  const matchedDimensionConfig = config.find(
    ({ valueDimension: candidate }) => cubeDimensionToKey(candidate) === cubeDimensionToKey(valueDimension),
  );

  const text = matchedDimensionConfig?.filterFormat?.(label, t) ?? label;

  return <Tag text={text} onClose={onRemove} />;
};

export const FilterWidget = ({ dimensions }: FilterWidgetProps) => {
  const { t } = useTranslation("insights");
  const {
    actions: { removeFilter, resetState, setDateRange, applyFilterForDimensions },
    state: { filters, filterValues, dateRange },
  } = useDashboardContext();

  const dateRangeFilterLabel = useMemo(() => {
    const startDateLabel = format(dateRange.start, "dd MMM yyyy");
    const endDateLabel = format(dateRange.end, "dd MMM yyyy");

    return t("filters.time_filter", { start: startDateLabel, end: endDateLabel });
  }, [dateRange.start, dateRange.end, t]);

  const handleDateRangeChange = (newDateRange: [start: Date | null | undefined, end: Date | null | undefined]) => {
    const [start, end] = newDateRange;

    if (start && end) {
      setDateRange({ start, end });
    }
  };

  const dateRangeValue = useMemo(() => {
    return [dateRange.start, dateRange.end] as [Date, Date];
  }, [dateRange]);

  return (
    <StickyContainer>
      <div className={styles.filterWidgetContainer}>
        <GroupedControls>
          <GroupedControls.Item>
            <DateRangePicker
              value={dateRangeValue}
              onValueChange={handleDateRangeChange}
              label={
                <>
                  <span>{t("general.date_range_label")}</span>
                  <Icon icon="angleDown" />
                </>
              }
              hasInlineLabel
            />
          </GroupedControls.Item>
          {dimensions.map((config: DimensionFilterConfig) => (
            <GroupedControls.Item key={cubeDimensionToKey(config.valueDimension)}>
              <DimensionValueSelect
                key={cubeDimensionToKey(config.valueDimension)}
                valueDimension={config.valueDimension}
                labelDimension={config.labelDimension}
                placeholder={config.placeholder?.(t)}
                onChange={(newValues) => {
                  applyFilterForDimensions(config, newValues);
                }}
                value={filterValues ? filterValues[cubeDimensionToKey(config.valueDimension)]! : undefined}
              />
            </GroupedControls.Item>
          ))}
        </GroupedControls>
        <TagList>
          <Tag text={dateRangeFilterLabel} />
          {filters.map((filter: Filter) => (
            <AppliedFilter
              {...filter}
              onRemove={() => removeFilter(filter)}
              dimensions={dimensions}
              key={`${cubeDimensionToKey(filter.valueDimension)}-${filter.value}`}
            />
          ))}
          <Button variant="subtle" size="small" onClick={resetState}>
            <Icon icon="times" />
            {t("filters.reset")}
          </Button>
        </TagList>
      </div>
    </StickyContainer>
  );
};
