import classNames from "classnames";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { SCROLL_OBSERVABLE_PROPS } from "../ScrollContainer";
import styles from "./Tab.module.scss";

type TabProps = {
  title: string;
  href: string;
  disabled?: boolean;
  disabledText?: string;
  testId?: string;
  badge?: ReactNode;
};

export const Tab = ({ title, href, badge, disabled = false, testId }: TabProps) => {
  if (disabled) {
    return (
      <div className={classNames(styles.link, styles.disabled)} data-testid={testId} {...SCROLL_OBSERVABLE_PROPS}>
        {/* TODO: reinstate the tooltip when it is implemented using a popover */}
        {/* <Tooltip message={disabledText} position="down"> */}
        <span>{title}</span>
        {badge && <div className={styles.badge}>{badge}</div>}
        {/* </Tooltip> */}
      </div>
    );
  }

  return (
    <NavLink className={styles.link} role="button" to={href} data-testid={testId} {...SCROLL_OBSERVABLE_PROPS}>
      <span>{title}</span>
      {badge && <div className={styles.badge}>{badge}</div>}
    </NavLink>
  );
};
