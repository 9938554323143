import { InvoiceMethod } from "@simplicate/api-client";
import { t } from "@simplicate/translations";
import * as Yup from "yup";
import { ProjectServiceForm } from "./types";

export const buildValidationSchema = () =>
  Yup.object<ProjectServiceForm>({
    defaultService: Yup.string().required("error_default_service_required"),
    invoiceMethod: Yup.string().required("error_invoice_method_required"),
    description: Yup.string().max(100, "error_description_too_long").required("error_description_required"),
    explanation: Yup.string().max(500, "error_explanation_too_long").nullable(),
    invoiceableFrom: Yup.date().optional(),
    timeframe: Yup.object({
      startDate: Yup.date()
        .optional()
        .test(
          "error_end_date_requires_start_date",
          t("error_end_date_requires_start_date", { ns: "project_services" }),
          (_, { parent }: { parent: { endDate?: Date; startDate?: Date } }) =>
            parent.endDate && !parent.startDate ? false : true,
        ),
      endDate: Yup.date()
        .optional()
        .when(["startDate"], ([startDate], schema) => {
          if (!startDate) return schema;

          return schema.min(startDate, "error_end_date_before_start_date");
        }),
    }),
    revenueGroup: Yup.string().required("error_revenue_group_required"),
    vatCode: Yup.string().required("error_vat_code_required"),
    hourTypes: Yup.array().when(["canRegisterHours", "invoiceMethod"], ([canRegisterHours, invoiceMethod], schema) => {
      // TODO add canRegisterCost condition
      // If canRegisterCost && invoiceMethod === InvoiceMethod.time_and_expenses && costTypes.length > 0, then this is not required

      if (canRegisterHours && invoiceMethod === InvoiceMethod.time_and_expenses) {
        return schema.min(1, t("error_add_at_least_one_hour_type", { ns: "project_services" }));
      }

      return schema;
    }),
    costTypes: Yup.array(),
    canRegisterHours: Yup.boolean(),
    canRegisterCosts: Yup.boolean(),
    invoicePrice: Yup.object().when("invoiceMethod", {
      is: InvoiceMethod.fixed_price,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
    subscriptionCycle: Yup.string().when("invoiceMethod", {
      is: InvoiceMethod.subscription,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
    hasRegistrationTimeframe: Yup.boolean(),
    registrationTimeframe: Yup.object({
      startDate: Yup.date().optional(),
      endDate: Yup.date()
        .optional()
        .when(["startDate"], ([startDate], schema) => {
          if (!startDate) {
            return schema;
          }

          return schema.min(startDate, "error_end_date_before_start_date");
        }),
    }),
  });
// TODO: Add back the error_either_hours_or_costs_required error
