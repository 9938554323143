/* istanbul ignore file -- cannot reasonably test this component since it is very DOM heavy */
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useSortableListContext } from "../SortableList.context";
import { SortableListRecord } from "../SortableList.types";

export const useDraggable = <T extends SortableListRecord>(value?: T) => {
  const { moveItem, isSortable } = useSortableListContext();
  const [isOnTopHalf, setIsOnTopHalf] = useState(false);
  const topHalfRef = useRef(false);
  const ref = useRef<HTMLLIElement>(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "LIST_ITEM",
      item: value,
      canDrag: () => !!value && isSortable,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [value, isSortable],
  );

  const [{ isOver }, drop] = useDrop<T, void, { isOver: boolean }>(
    () => ({
      accept: "LIST_ITEM",
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }),
      }),
      hover: (_, monitor) => {
        const hoverBoundingRect = ref.current!.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset ? clientOffset.y - hoverBoundingRect.top : 0;

        const isTopHalf = hoverClientY < hoverMiddleY;

        topHalfRef.current = isTopHalf;
        setIsOnTopHalf(isTopHalf);
      },
      drop: (item) => {
        moveItem(item.id, value!.id, topHalfRef.current ? "before" : "after");
      },
    }),
    [moveItem, value, setIsOnTopHalf],
  );

  drag(drop(ref));

  return {
    isSortable,
    isDragging,
    isOver,
    isOnTopHalf,
    ref,
  };
};
