import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

type PortalContext<T = HTMLElement> = {
  targetRefs: TargetRefs<T>;

  /** Returns a clean up function, which when called removes the ref that was registered. */
  setTargetRef: (key: string, ref: React.MutableRefObject<T | null>) => () => void;
};
type TargetRefs<T = HTMLElement> = Record<string, React.MutableRefObject<T | null> | undefined>;

const PortalContext = createContext<PortalContext | null>(null);

export const PortalProvider = ({ children }: PropsWithChildren) => {
  const [targetRefs, setTargetRefs] = useState<TargetRefs>({});

  const setTargetRef = useCallback((key: string, ref: React.MutableRefObject<HTMLElement | null>) => {
    setTargetRefs((previous) => ({ ...previous, [key]: ref }));

    return () => {
      setTargetRefs((previous) => {
        const next = { ...previous };

        delete next[key];

        return next;
      });
    };
  }, []);

  return <PortalContext.Provider value={{ targetRefs, setTargetRef }}>{children}</PortalContext.Provider>;
};

/* istanbul ignore next */
export const usePortalContext = () => {
  const context = useContext(PortalContext);

  if (context === null) {
    throw new Error("usePortalContext must be used within a PortalProvider");
  }

  return context;
};
