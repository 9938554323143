import { useTranslation } from "@simplicate/translations";
import { Button, Icon, Modal, SortableList } from "@simplicate/ui";
import { useCallback, useMemo, useState } from "react";
import { useDashboardContext } from "../../components";
import { Column, cubeDimensionToKey } from "../../types";
import styles from "./ViewActionsWidget.module.scss";

type ViewActionsWidgetProps = {
  columns: Column[];
};

type ColumnViewState = { id: string; column: Column; visible: boolean }[];

export const ViewActionsWidget = ({ columns: allColumns }: ViewActionsWidgetProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    state: { columns },
    actions,
  } = useDashboardContext();

  const { t } = useTranslation("insights");

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onClose = useCallback(/* istanbul ignore next */ () => setModalOpen(false), []);

  const columnsState = useMemo(
    () =>
      allColumns
        .filter((column) => !column.hidden)
        .map((column) => ({
          id: cubeDimensionToKey(column.dimension),
          column,
          visible:
            columns.find((stateColumn) => stateColumn.column === cubeDimensionToKey(column.dimension))?.visible ?? true,
        })),
    [allColumns, columns],
  );

  const [columnsSelections, setColumnsSelections] = useState<ColumnViewState>(
    columnsState.filter((column) => column.visible),
  );
  const [columnsOrder, setColumnsOrder] = useState<ColumnViewState>(columnsState);

  const handleSelectionChange = useCallback((newSelection: { id: string; column: Column; visible: boolean }[]) => {
    const updatedSelection = newSelection.map((selection) => ({
      ...selection,
      visible: /* istanbul ignore next*/ selection.visible || true,
    }));

    setColumnsSelections(updatedSelection);
  }, []);

  const handleOrderChange = useCallback((newOrder: { id: string; column: Column; visible: boolean }[]) => {
    setColumnsOrder(newOrder);
  }, []);

  const handleSubmit = useCallback(() => {
    const columns = columnsOrder.map((column, index) => ({
      column: cubeDimensionToKey(column.column.dimension),
      order: index,
      visible: columnsSelections.find((selection) => selection.id === column.id)?.visible ?? false,
    }));

    actions.updateColumns(columns);

    onClose();
  }, [columnsSelections, columnsOrder, actions, onClose]);

  const handleReset = useCallback(() => {
    setColumnsSelections(columnsState.filter((column) => column.visible));
    setColumnsOrder(columnsState);
    onClose();
  }, [columnsState, onClose]);

  return (
    <>
      <Button size="medium" variant="secondary" onClick={openModal} title={t("general.configuration_columns")}>
        <Icon icon="gear" />
      </Button>
      <Modal
        title={t("general.data_grid")}
        isOpen={modalOpen}
        onClose={handleReset}
        footerComponent={
          <>
            <Button variant="primary" size="medium" type="submit" onClick={handleSubmit}>
              {t("general.button_add")}
            </Button>
            <Button variant="subtle" size="medium" onClick={handleReset}>
              {t("general.button_cancel")}
            </Button>
          </>
        }
      >
        <div className={styles.modalContent}>
          <div className={styles.description}>{t("general.data_grid_description")}</div>
          <SortableList
            name="columnsList"
            testId="columnsList"
            data={columnsOrder}
            selection={columnsSelections}
            isSortable={true}
            isSelectable={true}
            onOrderChange={handleOrderChange}
            onSelectionChange={handleSelectionChange}
          >
            {(item) => <div>{item.column.title(t)}</div>}
          </SortableList>
        </div>
      </Modal>
    </>
  );
};
