import classnames from "classnames";
import { Icon } from "../Icon";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[size]
import styles from "./Spinner.module.scss";

type SpinnerProps = {
  size?: "large" | "small";
  message?: string;
  testId?: string;
};

export const Spinner = ({ size = "large", message, testId }: SpinnerProps) => {
  return (
    <div className={classnames(styles.spinner, styles[size])}>
      <span className={styles.icon}>
        <Icon spin icon="spinner" variant={size === "large" ? "default" : "solid"} testId={testId} />
      </span>
      {message && <span>{message}</span>}
    </div>
  );
};
