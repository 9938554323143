import { useGetCurrentUserQuery, usePermission } from "@simplicate/api-client";

export const useShowTimetable = (id?: string) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const hasViewOwnTimetablePermission = usePermission("hrm", "view_own_raster").granted;

  if (!id) {
    return false;
  }

  const isCurrentUser = currentUser?.data?.employee_id === id;
  const showTimeTable = !isCurrentUser || hasViewOwnTimetablePermission;

  return showTimeTable;
};
