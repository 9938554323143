import { format } from "date-fns";
import { RefObject } from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { Button } from "../Button";
import { Icon } from "../Icon";
import styles from "./DatePickerHeader.module.scss";

const MONTH_YEAR_FORMAT = "MMMM yyyy";

type DatePickerHeaderProps = Pick<
  ReactDatePickerCustomHeaderProps,
  "date" | "decreaseMonth" | "increaseMonth" | "nextMonthButtonDisabled" | "prevMonthButtonDisabled"
> & { testId?: string; focusableRef?: RefObject<HTMLButtonElement> };

export const DatePickerHeader = ({
  decreaseMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled,
  date,
  testId,
  focusableRef,
}: DatePickerHeaderProps) => {
  const formattedDate = format(date, MONTH_YEAR_FORMAT);

  return (
    <div className={styles.monthNavigationContainer}>
      <span className={styles.currentMonth}>{formattedDate}</span>
      <Button
        onClick={decreaseMonth}
        variant="subtle"
        disabled={prevMonthButtonDisabled}
        testId={testId && `${testId}-previous-month`}
        ref={focusableRef}
      >
        <Icon icon="angleLeft" />
      </Button>
      <Button
        onClick={increaseMonth}
        variant="subtle"
        disabled={nextMonthButtonDisabled}
        testId={testId && `${testId}-next-month`}
      >
        <Icon icon="angleRight" />
      </Button>
    </div>
  );
};
