import { RenderParams } from "@minoru/react-dnd-treeview";
import { Trans } from "@simplicate/translations";
import { Clickable } from "@simplicate/ui";
import classNames from "classnames";
import styles from "./EmptyGroupMessage.module.scss";

type EmptyGroupMessageProps = {
  isDropTarget?: RenderParams["isDropTarget"];
  linkTo: string;
};

export const EmptyGroupMessage = ({ isDropTarget, linkTo }: EmptyGroupMessageProps) => {
  return (
    <div className={classNames(styles.emptyMessage, isDropTarget && styles.dropTarget)}>
      <Trans i18nKey="empty_service_group_message" ns="grouped_services_manager">
        drag_and_drop_or
        <Clickable className={styles.link} to={linkTo}>
          add_new
        </Clickable>
      </Trans>
    </div>
  );
};
