import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";
import styles from "./TextArea.module.scss";

type TextAreaProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, "value"> & {
  name: string;
  label: string;
  label_tooltip?: string;
  testId?: string;
  hasError?: boolean;
  disabled?: boolean;
  value?: string;
};

export const TextArea = ({
  testId,
  name,
  label,
  label_tooltip,
  value = "",
  hasError = false,
  disabled = false,
  className,
  ...props
}: TextAreaProps) => {
  return (
    <div
      className={classNames(styles.wrapper, disabled && styles.disabled, className)}
      data-testid={`inputWrapper-${testId}`}
    >
      {label && (
        <div className={styles.labelWrapper}>
          <label htmlFor={name}>{label}</label>
          {label_tooltip && (
            <Tooltip message={label_tooltip} position="up">
              <Icon icon="infoCircle" />
            </Tooltip>
          )}
        </div>
      )}
      <div
        data-testid={testId && `${testId}-wrapper`}
        className={classNames(styles.inputWrapper, hasError && styles.invalid)}
      >
        <textarea
          id={name}
          name={name}
          value={value ?? ""}
          className={styles.input}
          data-testid={testId}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
};
