import classNames from "classnames";
import { ComponentProps, type PropsWithChildren, forwardRef } from "react";
import { Clickable } from "../Clickable";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[size] and styles[variant]
import styles from "./Button.module.scss";

export type ButtonProps = PropsWithChildren<
  ComponentProps<typeof Clickable> & {
    ariaLabel?: string;
    variant?: "danger" | "invisible" | "primary" | "secondary" | "subtle";
    size?: "medium" | "small";
    disabled?: boolean;
    hasInlinePadding?: boolean;
  }
>;

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      ariaLabel,
      disabled = false,
      testId,
      variant = "primary",
      size = "medium",
      children,
      hasInlinePadding = true,
      ...clickableProps
    },
    ref,
  ) => {
    return (
      <Clickable
        aria-label={ariaLabel}
        className={classNames(
          styles.reset,
          styles.pill,
          styles[variant],
          styles[size],
          hasInlinePadding && styles.hasInlinePadding,
          disabled && styles.disabled,
        )}
        disabled={disabled}
        testId={testId}
        ref={ref}
        {...clickableProps}
      >
        {children}
      </Clickable>
    );
  },
);

Button.displayName = "Button";
