import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./GroupedControlsGroup.module.scss";

type GroupedVariant = "default" | "large";

type GroupedControlsGroupProps = PropsWithChildren & {
  hasGap?: boolean;
  variant?: GroupedVariant;
  testId?: string;
};

export const GroupedControlsGroup = ({
  hasGap = false,
  variant = "default",
  children,
  testId,
}: GroupedControlsGroupProps) => {
  return (
    <div
      className={classNames(
        styles.groupedControlsContainer,
        variant === "large" && styles.large,
        !hasGap && styles.noGap,
      )}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
