import { ColumnsLayout } from "@simplicate/ui";
import { PropsWithChildren } from "react";
import classes from "./HoursReportPage.module.scss";

export const HoursReportPageTemplate = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout>{children}</ColumnsLayout>;
};

HoursReportPageTemplate.Header = function HeaderSlot({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot onSmallScreens={12} className={classes.header}>
      {children}
    </ColumnsLayout.Slot>
  );
};
HoursReportPageTemplate.Filters = function FiltersSlot({ children }: PropsWithChildren) {
  return <ColumnsLayout.Slot>{children}</ColumnsLayout.Slot>;
};
HoursReportPageTemplate.Settings = function SettingsSlot({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot className={classes.settings} onSmallScreens={1}>
      {children}
    </ColumnsLayout.Slot>
  );
};

HoursReportPageTemplate.KpiContainer = function KpiContainerSlot({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot hasSubgridOnColumns className={classes.kpiContainer}>
      {children}
    </ColumnsLayout.Slot>
  );
};

HoursReportPageTemplate.Kpi = function Kpi({ children }: PropsWithChildren) {
  return (
    <ColumnsLayout.Slot onMediumScreens={6} onHugeScreens={3}>
      {children}
    </ColumnsLayout.Slot>
  );
};

HoursReportPageTemplate.Table = function TableSlot({ children }: PropsWithChildren) {
  return <ColumnsLayout.Slot>{children}</ColumnsLayout.Slot>;
};
