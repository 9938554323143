import { skipToken, useGetPropositionQuery } from "@simplicate/api-client";
import { PageHeader } from "@simplicate/top-bar";
import { useParams } from "react-router-dom";
import { RelationTag } from "../../RelationTag";

// eslint-disable-next-line complexity -- todo: refactor
export const ToBeInvoicedSubHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { data: proposition } = useGetPropositionQuery(id ?? skipToken);

  const propositionData = proposition?.data;

  if (!propositionData) {
    return null;
  }

  const projectNumber = propositionData?.project.project_number;
  const projectName = propositionData?.project.name ?? "";
  const projectTitle = projectNumber ? `${projectNumber}. ${projectName}` : projectName;

  const organizationName = propositionData?.project?.organization?.name;
  const organizationId = propositionData?.project?.organization?.id;
  const personName = propositionData?.project.person?.full_name;
  const personId = propositionData?.project.person?.id;
  const relationName = organizationName ? organizationName : personName;

  return (
    <PageHeader title={projectTitle}>
      <RelationTag organizationId={organizationId} personId={personId} name={relationName} />
    </PageHeader>
  );
};
