import { usePermission } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { ModuleIcon, NavCard, Page, Tag } from "@simplicate/ui";
import { colorModulesGreen100, colorModulesGreen500 } from "@simplicate-software/design-tokens";
import { IndexPageTemplate } from "./IndexPage.template";

export const IndexPage = () => {
  const { t } = useTranslation("insights");

  const { granted: hasAccessToHours } = usePermission("hours", "view");
  const { granted: hasHoursReportingPermission } = usePermission("hours", "hoursrapportage_all");

  return (
    <Page>
      <IndexPageTemplate>
        <IndexPageTemplate.Title title={t("index_page_title")} />
        <NavCard.Container>
          {hasAccessToHours && hasHoursReportingPermission && (
            <>
              <NavCard
                title={t("hours.report_title")}
                description={t("hours.report_description")}
                to="dashboard/hours"
                accentColor={colorModulesGreen100}
                icon={<ModuleIcon module="hours" color={colorModulesGreen500} />}
                footer={<Tag text={t("hours.category_label")} variant="color" color={colorModulesGreen100} />}
                utilityCorner={<Tag text={t("beta_label")} variant="light" />}
              />
              <NavCard
                title={t("hours.details_report_title")}
                description={t("hours.details_report_description")}
                to="dashboard/hours-details"
                accentColor={colorModulesGreen100}
                icon={<ModuleIcon module="hours" color={colorModulesGreen500} />}
                footer={<Tag text={t("hours.category_label")} variant="color" color={colorModulesGreen100} />}
                utilityCorner={<Tag text={t("beta_label")} variant="light" />}
              />
            </>
          )}
        </NavCard.Container>
      </IndexPageTemplate>
    </Page>
  );
};
