import classnames from "classnames";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[direction]
import styles from "./RadioButton.module.scss";

type RadioButtonValueType = number | string | undefined;

type RadioButtonProps<TValue extends RadioButtonValueType> = {
  options: RadioButtonOption<TValue>[];
  label?: string;
  name: string;
  value?: TValue;
  onChange: (value: TValue) => void;
  direction?: "horizontal" | "vertical";
};

type RadioButtonOption<TValue extends RadioButtonValueType> = {
  value: TValue;
  label: string;
  disabled?: boolean;
};

export const RadioButton = <TValue extends RadioButtonValueType>({
  options,
  label,
  name,
  value,
  onChange,
  direction = "vertical",
}: RadioButtonProps<TValue>) => {
  return (
    <div>
      {label && <span className={classnames(styles.radioButtonGroupLabel)}>{label}</span>}
      <div className={classnames(styles.radioButtonGroup, styles[direction])}>
        {options.map((option) => (
          <label
            key={`radio-button${label}-${option.value}`}
            className={classnames(styles.radioButton, option.disabled && styles.disabled)}
          >
            <input
              type="radio"
              name={name}
              value={option.value}
              onChange={() => onChange(option.value)}
              checked={value === option.value}
              data-testid={`radio-button-for-${option.value}`}
              disabled={option.disabled}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};
