import { enUS, nl } from "date-fns/locale";
import { PropsWithChildren, useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { PortalProvider } from "../PortalProvider";
import { DragAndDropProvider } from "../SortableList";

const DEFAULT_LOCALE = "nl";

registerLocale("nl", nl);
registerLocale("en", enUS);

type UIProviderProps = PropsWithChildren<{
  locale?: "en" | "nl";
}>;

export const UIProvider = ({ locale = DEFAULT_LOCALE, children }: UIProviderProps) => {
  useEffect(() => {
    setDefaultLocale(locale);
  }, [locale]);

  return (
    <PortalProvider>
      <DragAndDropProvider>{children}</DragAndDropProvider>
    </PortalProvider>
  );
};
