import { Spinner } from "@simplicate/ui";
import classNames from "classnames";
import styles from "./LoadingFallback.module.scss";

type LoadingFallbackProps = {
  text?: string;
  type?: "group";
};

export const LoadingFallback = ({ text, type }: LoadingFallbackProps) => {
  return (
    <div className={classNames(styles.spinnerOverlay, type === "group" && styles.group)} data-testid="loading-fallback">
      <div className={styles.spinnerContainer}>
        <Spinner size="large" />
        {text && <span className={styles.loadingStateText}>{text}</span>}
      </div>
    </div>
  );
};
