import { Big, CostType, transformMoneyToMoneyFromAPI, InvoiceMethod } from "@simplicate/api-client";
import { CostTypeInCreateServiceBody, CostTypeInService } from "./data/endpoints";
import { randomUUID } from "./utils";
import type { CostTypeInForm, ValidCostTypeInForm } from "./types";

export const transformCostTypeInServiceToCostTypeInForm = (costTypeInService: CostTypeInService): CostTypeInForm => {
  const costType = {
    id: costTypeInService.id,
    name: costTypeInService.defaultCostType.name,
    defaultCostType: {
      id: costTypeInService.defaultCostType.id,
      name: costTypeInService.defaultCostType.name,
    },
    purchasePrice: {
      amount: Big(costTypeInService.purchasePrice.amount),
      currency: costTypeInService.purchasePrice.currency,
    },
    sellingPrice: {
      amount: Big(costTypeInService.sellingPrice.amount),
      currency: costTypeInService.sellingPrice.currency,
    },
    margin: costTypeInService.margin,
    total: {
      amount: Big(costTypeInService.total.amount),
      currency: "EUR" as const,
    },
    isInvoiceable: costTypeInService.isInvoiceable ?? true,
    hasRegistrations: costTypeInService.hasRegistrations,
  };

  if (costTypeInService.defaultCostType.usePricePerUnit && costTypeInService.defaultCostType.unit) {
    return {
      ...costType,
      usePricePerUnit: true,
      unit: costTypeInService.defaultCostType.unit,
      quantity: 1,
    };
  }

  return {
    ...costType,
    usePricePerUnit: false,
  };
};

export const transformCostTypeToCostTypeInForm = (costType: CostType, label: string): CostTypeInForm => {
  const costTypeInForm = {
    /*
      We only need a unique id to support editing the new costType's values in the form,
      the ProjectService is submitted without these costType generated ids
      and the back-end will generate the ids itself
    */
    id: randomUUID(),
    name: label,
    defaultCostType: {
      id: costType.id,
      name: costType.name,
    },
    purchasePrice: {
      amount: Big(costType.purchasePrice.amount),
      currency: costType.purchasePrice.currency,
    },
    sellingPrice: {
      amount: Big(costType.sellingPrice.amount),
      currency: costType.sellingPrice.currency,
    },
    margin: costType.margin,
    total: {
      amount: Big(costType.sellingPrice.amount),
      currency: "EUR" as const,
    },
    isInvoiceable: true,
    hasRegistrations: false,
  };

  if (costType.usePricePerUnit && costType.unit) {
    return {
      ...costTypeInForm,
      usePricePerUnit: true,
      unit: costType.unit,
      quantity: 1,
    };
  }

  return {
    ...costTypeInForm,
    usePricePerUnit: false,
  };
};

export const transformToCreateServiceCostType = (
  costType: ValidCostTypeInForm,
  invoiceMethod: InvoiceMethod,
): CostTypeInCreateServiceBody => {
  return {
    id: costType.id,
    defaultCostTypeId: costType.defaultCostType.id,
    description: costType.name,
    purchasePrice: transformMoneyToMoneyFromAPI(costType.purchasePrice),
    sellingPrice: transformMoneyToMoneyFromAPI(costType.sellingPrice),
    quantity: costType.quantity,
    isInvoiceable: invoiceMethod === InvoiceMethod.time_and_expenses ? costType.isInvoiceable : undefined,
  };
};
