import { PropsWithChildren } from "react";
import { ColumnsLayout } from "../ColumnsLayout";
import styles from "./NavCardContainer.module.scss";

/**
 * Container for NavCard components fixing the appropriate gaps and alignment with the grid.
 * This component CAN be used as a slot for a ColumnsLayout.
 */
export const NavCardContainer = ({ children }: PropsWithChildren) => {
  return <ColumnsLayout className={styles.container}>{children}</ColumnsLayout>;
};
