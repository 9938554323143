import classnames from "classnames";
import { ReactNode } from "react";
import { Icon } from "../Icon";
import { Portal } from "../Portal";
import { usePortalContext } from "../PortalProvider";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[size]
import styles from "./Modal.module.scss";

export const MODAL_ANCHOR = "global-overlay";

type ModalProps = {
  title?: string;
  size?: "large" | "medium" | "small";
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  footerComponent?: ReactNode;
  testId?: string;
};

// TODO: add backdrop click dismiss
// TODO: add escape key dismiss
// TODO: add focus trap
// TODO: force inert on siblings of the anchor while modal is open
// TODO: add other accessibility features (see: https://www.magentaa11y.com/checklist-web/modal-dialog/)
// This implementation can probably be replaced with a dialog element (see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog)
export const Modal = ({ title, isOpen, size = "small", onClose, children, footerComponent, testId }: ModalProps) => {
  const { targetRefs } = usePortalContext();

  if (!isOpen) {
    return null;
  }

  return (
    <Portal targetRef={targetRefs[MODAL_ANCHOR]}>
      <div className={classnames(styles.modalOverlay)} data-testid={testId}>
        <div className={classnames(styles.modal, styles[size])}>
          {title ? <span className={classnames(styles.modalTitle)}>{title}</span> : null}
          <button
            className={classnames(styles.modalCloseButton)}
            onClick={onClose}
            data-testid={`${testId}-close-button`}
          >
            <Icon icon="times" />
          </button>
          <div className={classnames(styles.modalContentContainer)}>{children}</div>
          {footerComponent ? <div className={classnames(styles.modalFooterContainer)}>{footerComponent}</div> : null}
        </div>
      </div>
    </Portal>
  );
};
