import { addMonths, endOfMonth, isAfter, isBefore, isSameMonth, startOfMonth } from "date-fns";
import { useCallback, useReducer } from "react";
import { DateRange } from "./DateRangePicker.types";

type DisplayWindow = {
  start: Date;
  end: Date;
};

type UpdateStartMonthAction = { type: "UPDATE_START_MONTH"; date: Date };
type UpdateEndMonthAction = { type: "UPDATE_END_MONTH"; date: Date };

type DisplayWindowAction = UpdateEndMonthAction | UpdateStartMonthAction;

function displayWindowReducer(state: DisplayWindow, action: DisplayWindowAction): DisplayWindow {
  switch (action.type) {
    case "UPDATE_START_MONTH":
      return {
        ...state,
        start: startOfMonth(action.date),
        end: isAfter(endOfMonth(action.date), state.end) ? startOfMonth(addMonths(action.date, 1)) : state.end,
      };

    case "UPDATE_END_MONTH":
      return {
        ...state,
        start: isBefore(action.date, endOfMonth(state.start)) ? startOfMonth(addMonths(action.date, -1)) : state.start,
        end: startOfMonth(action.date),
      };
  }
}

export const useDisplayWindow = (dateRange: DateRange) => {
  const [state, dispatch] = useReducer(displayWindowReducer, null, () => {
    const start = dateRange.at(0);
    const end = dateRange.at(1);

    const startMonth = start ? new Date(start) : new Date();
    const endDate = end ? new Date(end) : new Date();
    const endMonth = isSameMonth(startMonth, endDate) ? addMonths(endDate, 1) : endDate;

    return {
      start: startOfMonth(startMonth),
      end: startOfMonth(endMonth),
    };
  });

  const handleStartMonthChange = useCallback((date: Date) => {
    dispatch({ type: "UPDATE_START_MONTH", date });
  }, []);

  const handleEndMonthChange = useCallback((date: Date) => {
    dispatch({ type: "UPDATE_END_MONTH", date });
  }, []);

  return {
    displayMonthStart: state.start,
    displayMonthEnd: state.end,
    handleStartMonthChange,
    handleEndMonthChange,
  };
};
