import classNames from "classnames";
import { Clickable, ClickableProps } from "../Clickable";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[variant]
import styles from "./Link.module.scss";

type LinkProps = ClickableProps & {
  variant?: "danger" | "normal";
  testId?: string;
};

export const Link = ({ children, testId = "link", variant = "normal", ...extraProps }: LinkProps) => (
  <Clickable {...extraProps} testId={testId} className={classNames(styles.link, styles[variant])}>
    {children}
  </Clickable>
);
