import { useCallback, useMemo } from "react";
import { useSortableListContext } from "../SortableList.context";
import { SortableListRecord } from "../SortableList.types";

export const useSelectable = (value: SortableListRecord) => {
  const { isSelectable, selectedItems, name, toggleSelection } = useSortableListContext();

  const isSelected = useMemo(
    () => selectedItems.find(({ id }) => value.id === id) !== undefined,
    [selectedItems, value],
  );

  const handleChange = useCallback(() => {
    toggleSelection(value);
  }, [toggleSelection, value]);

  return {
    isSelectable,
    checkboxProps: {
      name,
      value: isSelected,
      onChange: handleChange,
    },
  };
};
