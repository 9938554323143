import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IframeActionPayload, IframeActionType, IframeEvent, PostIFrameMessage } from "./iFrameSlice.types";

type TabBadgeMessage = {
  action: "IFRAME_TAB_INDICATOR";
  payload: { tab: string; value?: number };
};

type PostMessageHandler = (message: PostIFrameMessage) => void;

type Message = {
  payload: IframeActionPayload;
  timestamp: number;
};

export type IframeState = {
  latestMessages: Partial<Record<IframeActionType, Message>>;
  tabBadges: Record<string, number | string | undefined>;
  postMessageToIframe: PostMessageHandler;
};

const initialState: IframeState = {
  latestMessages: {},
  tabBadges: {},
  /* c8 ignore next 1 */
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- This is a initial state
  postMessageToIframe: /* istanbul ignore next */ () => {},
};

const iFrameSlice = createSlice({
  name: "iFrame",
  initialState,
  reducers: {
    iframeMessageReceived: (state, action: PayloadAction<IframeEvent>) => {
      const newBadges = { ...state.tabBadges };

      action.payload.params
        .filter(
          (message): message is TabBadgeMessage =>
            message.action === "IFRAME_TAB_INDICATOR" && message.payload.tab !== undefined,
        )
        .forEach(({ payload }) => {
          newBadges[payload.tab] = payload.value;
        });

      return {
        ...state,
        tabBadges: newBadges,
        latestMessages: {
          ...state.latestMessages,
          ...action.payload.params.reduce(
            (acc, message) => ({
              ...acc,
              [message.action]: { payload: message.payload, timestamp: Date.now() },
            }),
            {},
          ),
        },
      };
    },

    setPostIframeMessageHandler: (state, action: PayloadAction<PostMessageHandler>) => ({
      ...state,
      postMessageToIframe: action.payload,
    }),

    postIframeMessage: (state, action: PayloadAction<PostIFrameMessage>) => {
      state.postMessageToIframe(action.payload);

      return state;
    },

    clearMessages: (state) => ({
      ...state,
      latestMessages: {},
    }),
  },
});

export const { iframeMessageReceived, setPostIframeMessageHandler, postIframeMessage, clearMessages } =
  iFrameSlice.actions;
export const iFrameReducer = iFrameSlice.reducer;
