import { lightColorBackgroundSecondaryDefault } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { CSSProperties, ComponentProps, ReactNode } from "react";
import { Clickable } from "../Clickable";
import { ColumnsLayout } from "../ColumnsLayout";
import styles from "./NavCard.module.scss";

const ACCENT_COLOR = "--accent-color" as keyof CSSProperties;

type NavCardProps = {
  accentColor?: string;
  description: string;
  footer?: ReactNode;
  icon: ReactNode;
  subtitle?: string;
  testId?: string;
  title: string;
  to: ComponentProps<typeof Clickable>["to"];
  utilityCorner?: ReactNode;
  utilityTop?: ReactNode;
  variant?: "default" | "suppressed";
};

export const NavCard = ({
  accentColor = lightColorBackgroundSecondaryDefault,
  description,
  footer,
  icon,
  subtitle,
  testId,
  title,
  to,
  utilityCorner,
  utilityTop,
  variant = "default",
}: NavCardProps) => {
  return (
    <ColumnsLayout.Slot
      onSmallScreens={12}
      onMediumScreens={6}
      onLargeScreens={4}
      onHugeScreens={3}
      hasSubgridOnRows
      className={styles.layoutSlot}
    >
      <Clickable
        to={to}
        className={classNames(styles.navCard, variant === "suppressed" && styles.suppressed)}
        style={{ [ACCENT_COLOR]: accentColor }}
        title={title}
        testId={testId}
      >
        {utilityTop && <div className={styles.utilityTop}>{utilityTop}</div>}
        <div className={styles.accent}>{icon}</div>
        <div className={styles.body}>
          <div className={styles.titleOverflowContainer}>
            <h3 className={styles.title}>{title}</h3>
            {utilityCorner && <div className={styles.utilityCorner}>{utilityCorner}</div>}
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
          <p className={styles.description}>{description}</p>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </Clickable>
    </ColumnsLayout.Slot>
  );
};
