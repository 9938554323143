import { skipToken, useGetQuoteQuery, useGetV2SalesQuery } from "@simplicate/api-client";
import { PageHeader } from "@simplicate/top-bar";
import { MessageBus } from "@simplicate/utils";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

export const QuoteSubHeader = () => {
  const { qid } = useParams<{ qid: string }>();
  const { data: quote } = useGetQuoteQuery(/* istanbul ignore next */ qid ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const subject = quote?.data?.quote_subject;
  const salesId = quote?.data?.sales_id;

  const { data: sales } = useGetV2SalesQuery(/* istanbul ignore next */ salesId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const salesSubject = sales?.data?.subject;

  const [quoteTitle, setQuoteTitle] = useState<string | undefined>();

  useEffect(() => {
    return MessageBus.subscribe("header", ({ title }) => {
      if (title === undefined || title === "Sales") {
        return;
      }
      setQuoteTitle(title);
    });
  }, []);

  const headerTitle = useMemo(() => {
    if (quoteTitle) {
      return quoteTitle;
    } else if (subject && subject !== salesSubject) {
      return subject;
    } else if (salesSubject) {
      return salesSubject;
    }
  }, [subject, salesSubject, quoteTitle]);

  return headerTitle ? <PageHeader title={headerTitle} /> : null;
};
