import { Big } from "@simplicate/api-client";
import { CurrencyFormat, NumberFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { buildColumnProps, Icon, Table } from "@simplicate/ui";
import { size5, size6, size7, size8, size9, size10, lightColorIconSuccess } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { memo, useState } from "react";
import { type CostTypeInForm } from "../../types";
import { CostTypeActionDropdown } from "./CostTypeActionDropdown";
import styles from "./CostTypeGrid.module.scss";
import { CostTypeGridFooter } from "./CostTypeGridFooter";
import { CostTypeNameCell } from "./CostTypeNameCell";

type CostTypeGridProps = {
  value?: CostTypeInForm[];
  onAddCostType: (costType: CostTypeInForm) => void;
  onRemoveCostType: (id: string) => void;
  onLabelChange: (id: string, label: string) => void;
  onIsInvoiceableToggled: (id: string) => void;
  onQuantityChange: (id: string, quantity: number | undefined) => void;
  onPurchasePriceChange: (id: string, purchasePrice: Big | undefined) => void;
  showIsInvoiceableColumn?: boolean;
  onMarginChange: (id: string, margin: number | undefined) => void;
  onSellingPriceChange: (id: string, sellingPrice: Big | undefined) => void;
  disabled?: boolean;
};

export const CostTypeGrid = memo(function CostTypeGrid({
  value = [],
  onAddCostType,
  onRemoveCostType,
  onLabelChange,
  onQuantityChange,
  onPurchasePriceChange,
  onMarginChange,
  onSellingPriceChange,
  onIsInvoiceableToggled,
  showIsInvoiceableColumn = false,

  disabled = false,
}: CostTypeGridProps) {
  const { t } = useTranslation("project_services");

  const [costTypeInEditMode, setCostTypeInEditMode] = useState<string>();

  const { totalBudgetMonetaryAmount, totalMonetaryAmount } = value.reduce(
    (totals, current) => {
      totals.totalBudgetMonetaryAmount = totals.totalBudgetMonetaryAmount.add(current.purchasePrice.amount ?? 0);
      totals.totalMonetaryAmount = totals.totalMonetaryAmount.add(current.total.amount);

      return totals;
    },
    {
      totalBudgetMonetaryAmount: Big(0),
      totalMonetaryAmount: Big(0),
    },
  );

  return (
    <Table
      testId="cost-type-grid"
      value={value}
      footer={
        <CostTypeGridFooter
          onAddCostType={onAddCostType}
          totalBudgetMonetaryValue={totalBudgetMonetaryAmount}
          totalMonetaryValue={totalMonetaryAmount}
          disabled={disabled}
        />
      }
      disabled={disabled}
    >
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          header: t("cost_type_label_header"),
          width: "dynamic",
          body: ({ id, name, defaultCostType }) => (
            <CostTypeNameCell
              label={name}
              defaultCostTypeName={defaultCostType.name}
              costTypeId={id}
              inEditMode={id === costTypeInEditMode}
              onConfirmEdit={
                /* istanbul ignore next - submitForm function is mocked in tests */ (newLabel) => {
                  onLabelChange(id, newLabel);
                  setCostTypeInEditMode(undefined);
                }
              }
              onCancelEdit={() => setCostTypeInEditMode(undefined)}
              disabled={disabled}
            />
          ),
        })}
      />
      {showIsInvoiceableColumn ? (
        <Table.Column
          {...buildColumnProps({
            header: t("cost_type_is_invoiceable_header"),
            field: "isInvoiceable",
            body: ({ isInvoiceable }: CostTypeInForm) =>
              isInvoiceable ? <Icon icon="check" color={lightColorIconSuccess} /> : <span>-</span>,
            width: size5,
          })}
        />
      ) : (
        <></>
      )}
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "quantity",
          header: t("cost_type_quantity_header"),
          body: ({ id, quantity, usePricePerUnit }) =>
            usePricePerUnit ? (
              <NumberFormat
                testId="input-cost-type-quantity"
                displayType="input"
                size="small"
                value={quantity}
                decimalScale={1}
                textAlign="right"
                onValueChange={
                  /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                  ({ floatValue }, { source }) => {
                    if (source.valueOf() === "prop") {
                      return;
                    }

                    return onQuantityChange(id, floatValue);
                  }
                }
                onBlur={() => {
                  if (quantity === undefined) {
                    onQuantityChange(id, 0);
                  }
                }}
                disabled={disabled}
              />
            ) : undefined,
          align: "right",
          width: size6,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "purchasePrice",
          header: t("cost_type_purchase_price_header"),
          align: "right",
          body: ({ id, purchasePrice, usePricePerUnit, unit }) => (
            <div className={styles.purchasePriceColumn}>
              <CurrencyFormat
                testId="input-cost-type-purchase-price"
                displayType="input"
                size="small"
                textAlign="right"
                value={purchasePrice.amount?.toString()}
                onValueChange={
                  /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                  ({ value }, { source }) => {
                    if (source.valueOf() === "prop") {
                      return;
                    }

                    if (value.length === 0) {
                      return onPurchasePriceChange(id, undefined);
                    }

                    return onPurchasePriceChange(id, Big(value));
                  }
                }
                onBlur={() => {
                  if (purchasePrice.amount === undefined) {
                    onPurchasePriceChange(id, Big(0));
                  }
                }}
                disabled={disabled}
              />
              {usePricePerUnit ? (
                <span title={unit} className={classNames(styles.ellipses, styles.unit)}>
                  {`/ ${unit}`}
                </span>
              ) : undefined}
            </div>
          ),
          width: size10,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "margin",
          header: t("cost_type_margin_header"),
          body: ({ id, margin }) => (
            <NumberFormat
              testId="input-cost-type-margin"
              displayType="input"
              size="small"
              value={margin}
              rightComponent="%"
              decimalScale={2}
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ floatValue }, { source }) => {
                  if (source.valueOf() === "prop") {
                    return;
                  }

                  return onMarginChange(id, floatValue);
                }
              }
              onBlur={() => {
                if (margin === undefined) {
                  onMarginChange(id, 0);
                }
              }}
              disabled={disabled}
            />
          ),
          align: "right",
          width: size7,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "sellingPrice",
          header: t("cost_type_selling_price_header"),
          align: "right",
          body: ({ id, sellingPrice }) => (
            <CurrencyFormat
              testId="input-cost-type-selling-price"
              displayType="input"
              size="small"
              textAlign="right"
              value={sellingPrice.amount?.toString()}
              onValueChange={
                /* istanbul ignore next -- Component is mocked as <span/> making input test cases impossible */
                ({ value }, { source }) => {
                  if (source.valueOf() === "prop") {
                    return;
                  }

                  if (value.length === 0) {
                    return onSellingPriceChange(id, undefined);
                  }

                  return onSellingPriceChange(id, Big(value));
                }
              }
              onBlur={() => {
                if (sellingPrice.amount === undefined) {
                  onSellingPriceChange(id, Big(0));
                }
              }}
              disabled={disabled}
            />
          ),
          width: size9,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          field: "total",
          header: t("cost_type_total_header"),
          align: "right",
          body: ({ total }) => (
            <CurrencyFormat
              className={classNames(styles.ellipses, styles.totalColumn)}
              testId="cost-type-total"
              displayType="text"
              decimalScale={2}
              value={total.amount.toString()}
              disabled={disabled}
            />
          ),
          width: size8,
        })}
      />
      <Table.Column
        {...buildColumnProps<CostTypeInForm>({
          body: ({ id, isInvoiceable, hasRegistrations }) => (
            <CostTypeActionDropdown
              id={id}
              isInvoiceable={isInvoiceable}
              edit={{
                onClick: (id) => setCostTypeInEditMode(id),
              }}
              toggleIsInvoiceable={{
                onClick: showIsInvoiceableColumn ? onIsInvoiceableToggled : undefined,
                disabled: isInvoiceable && hasRegistrations,
                tooltip:
                  isInvoiceable && hasRegistrations ? t("cannot_toggle_is_invoiceable_for_cost_type") : undefined,
              }}
              remove={{
                onClick: (id) => onRemoveCostType(id),
                disabled: hasRegistrations,
                tooltip: hasRegistrations ? t("cannot_remove_cost_type") : undefined,
              }}
              disabled={disabled}
            />
          ),
          width: size5,
        })}
      />
    </Table>
  );
});
