import { Slice, SliceCaseReducers, SliceSelectors, createSlice as createReduxSlice } from "@reduxjs/toolkit";
import { Reducer, combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import { rootReducer } from "../slices";
import { defaultReducers, persistor, store } from "../store";

type SliceConfiguration = {
  /**
   * Namespace in which to inject the slice. State from this slice will be available at
   * `state.<namespace>.<your slice name>`.
   *
   * @example
   * ```ts
   * createSlice({ namespace: "insights" }, { reducerPath: "dashboard_views", ... });
   * const { state: { insights: { dashboard_views } } } = useAppSelector();
   * ```
   */
  namespace: string;

  /**
   * When provided, this slice will be persisted in local storage under this key, otherwise the slice will not be
   * persisted at all.
   */
  localStorageKey?: string;
};

const namespaceReducers: Record<string, object> = {};

function withPersistor(reducer: Reducer, key: string) {
  return persistReducer({ key, storage: localStorage }, reducer);
}

function buildNamespaceReducer(slice: Slice, { localStorageKey, namespace }: SliceConfiguration) {
  const reducer = localStorageKey ? withPersistor(slice.reducer, localStorageKey) : slice.reducer;

  const combined = combineReducers({
    ...namespaceReducers[namespace],
    [slice.reducerPath]: reducer,
  });

  namespaceReducers[namespace] = combined;

  return combined;
}

function injectSlice(slice: Slice, options: SliceConfiguration) {
  const reducer = buildNamespaceReducer(slice, options);

  store.replaceReducer(
    combineReducers({
      ...defaultReducers,
      state: rootReducer.inject({ reducerPath: options.namespace, reducer }, { overrideExisting: true }),
    }),
  );

  if (options.localStorageKey) {
    persistor.persist();
  }
}

export function createSlice<
  TState,
  TSliceReducers extends SliceCaseReducers<TState>,
  TName extends string,
  TSliceSelectors extends SliceSelectors<TState>,
  TReducerPath extends string,
>(
  options: SliceConfiguration,
  sliceOptions: Parameters<typeof createReduxSlice<TState, TSliceReducers, TName, TSliceSelectors, TReducerPath>>[0],
) {
  const slice = createReduxSlice<TState, TSliceReducers, TName, TSliceSelectors, TReducerPath>(sliceOptions);

  injectSlice(slice, options);

  return slice;
}
