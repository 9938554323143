import { Spinner } from "@simplicate/ui";
import styles from "./LoadingAnimation.module.scss";

type LoadingAnimationProps = {
  text?: string;
  type?: "group-drag" | "row";
};

export const LoadingAnimation = ({ text, type }: LoadingAnimationProps) => {
  return (
    <div
      className={
        type === "row"
          ? `${styles.spinnerOverlay} ${styles.row}`
          : // eslint-disable-next-line sonarjs/no-nested-conditional -- todo: refactor
            type === "group-drag"
            ? `${styles.spinnerOverlay} ${styles.groupDrag}`
            : styles.spinnerOverlay
      }
    >
      <Spinner size="large" />
      {text && <span className={styles.loadingStateText}>{text}</span>}
    </div>
  );
};
