import classNames from "classnames";
import { PropsWithChildren } from "react";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles.listItem
import styles from "./List.module.scss";

type ListItemProps = PropsWithChildren<{
  disabled?: boolean;
  testId?: string;
  className?: string;
}>;

export const ListItem = ({ disabled = false, children, testId, className }: ListItemProps) => {
  return (
    <li className={classNames(styles.listItem, disabled && styles.disabled, className)} data-testid={testId}>
      {children}
    </li>
  );
};
