import { simplicateApiV1 } from "../components";

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- needed for the type
const LICENSES = [
  "accountancy_options",
  "accountingapp",
  "advanced_reporting",
  "bi_reporting",
  "get_started",
  "hrm_options",
  "purchase_costs",
  "invoice_options",
  "tariff_agreements",
  "customer_group_field",
  "users",
  "extra_users",
  "planning",
  "professional",
  "ultimate_efficiency",
  "multiple_project_invoice",
  "concept_services",
  "hours_approval",
  "workflowmanagement",
] as const;

export type License = (typeof LICENSES)[number];

export type LicenseConfig = {
  value: string;
  show: boolean;
  price_month: string;
  price_year: string;
  option_name: string;
  option: string;
  is_beta: "0" | "1";
  id: string;
  default_value: "0" | "1";
};

export type LicenseResponse = {
  licention: Partial<Record<License, LicenseConfig>>;
};

const endpoints = simplicateApiV1.injectEndpoints({
  endpoints: (builder) => ({
    getLicense: builder.query<LicenseResponse, void>({
      query: () => ({
        url: "/v1/internal/environment/getLicense",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetLicenseQuery } = endpoints;
