import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { forwardRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteGroupModal } from "../DeleteGroupModal";
import styles from "./ServiceGroupHeader.module.scss";

type ServiceGroupHeaderProps = {
  text: string;
  description?: string;
  editCallback?: () => void;
  deleteCallback?: (shouldDeleteServices: boolean) => void;
  serviceGroupId?: string;
};

export const ServiceGroupHeader = forwardRef<HTMLSpanElement, ServiceGroupHeaderProps>(function ServiceGroupHeader(
  { text, description, editCallback, deleteCallback, serviceGroupId },
  handleRef,
) {
  const { t } = useTranslation("grouped_services_manager");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id: saleId } = useParams<{ id: string }>();

  const handleDeleteGroup = (shouldDeleteServices: boolean) => {
    deleteCallback?.(shouldDeleteServices);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.header} data-testid="service-group-header">
        <div className={styles.headerLeft}>
          <span className={classNames(styles.grip)} ref={handleRef}>
            <Icon icon="grip" />
          </span>
          <div className={styles.headerText}>
            <h2 className={styles.groupName}>{text}</h2>
            {description && <p className={styles.groupDescription}>{description}</p>}
          </div>
        </div>
        <div className={styles.headerRight}>
          <ActionDropdown testId="dropdown-menu" ariaLabel={t("actions")}>
            <ActionDropdown.Action to={`/sales/${saleId}/services/new?serviceGroupId=${serviceGroupId}`}>
              <Icon icon="plus" />
              {t("add_service")}
            </ActionDropdown.Action>
            <ActionDropdown.Action onClick={editCallback}>
              <Icon icon="pen" />
              {t("edit")}
            </ActionDropdown.Action>
            <ActionDropdown.Action
              onClick={() => {
                setIsModalOpen(true);
              }}
              className={styles.deleteButton}
            >
              <Icon icon="trash" color={lightColorIconError} />
              {t("delete")}
            </ActionDropdown.Action>
          </ActionDropdown>
        </div>
      </div>
      {isModalOpen && (
        <div className={styles.deleteModal}>
          <DeleteGroupModal
            isOpen={isModalOpen}
            deleteServiceGroup={handleDeleteGroup}
            onClose={() => {
              setIsModalOpen(false);
            }}
          />
        </div>
      )}
    </>
  );
});
