import { RootState, TypedUseSelectorHook, useSelector } from "@simplicate/state";
import { DashboardSlice } from "./dashboardSlice";
export { useAppDispatch } from "@simplicate/state";

type InsightsSlices = {
  insights: {
    dashboard_views: DashboardSlice;
  };
};

export * from "./dashboardSlice";
export const useAppSelector: TypedUseSelectorHook<RootState<InsightsSlices>> = useSelector;
