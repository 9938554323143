import { useEnvironmentSetting } from "@simplicate/api-client";
import { Input, type InputProps } from "@simplicate/ui";
import classNames from "classnames";
import { NumericFormat, type NumericFormatProps, numericFormatter } from "react-number-format";
import styles from "./NumberFormat.module.scss";

type Variant = "default" | "numeric";

export type NumberFormatProps = NumericFormatProps<InputProps> & { variant?: Variant };

const defaultConfig = {
  displayType: "text",
  type: "text",
  customInput: Input,
} satisfies NumberFormatProps;

export const useNumericFormatter = (numStr: string | undefined) => {
  const decimalSeparator = useEnvironmentSetting("general", "decimal_separator")?.value ?? ".";
  const thousandSeparator = useEnvironmentSetting("general", "thousand_separator")?.value ?? ",";

  if (!numStr) {
    return;
  }

  return numericFormatter(numStr, {
    ...defaultConfig,
    decimalSeparator,
    thousandSeparator,
  });
};

export const NumberFormat = ({ testId, variant = "numeric", ...props }: NumberFormatProps) => {
  const decimalSeparator = useEnvironmentSetting("general", "decimal_separator")?.value ?? ".";
  const thousandSeparator = useEnvironmentSetting("general", "thousand_separator")?.value ?? ",";

  // TODO: find a way to remove this if-statement. But without is all the styling is lost.
  if (props.className) {
    const variantClass = {
      default: "",
      numeric: styles.numeric,
    }[variant];

    props.className = classNames(props.className, variantClass);
  }

  const configuredProps = {
    ...defaultConfig,
    decimalSeparator,
    thousandSeparator,
    ...props,
  };

  return <NumericFormat data-testid={testId} {...configuredProps} />;
};
