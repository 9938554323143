import { simplicateApiV2, createTagTypes } from "@simplicate/api-client";
import { differenceInMilliseconds } from "date-fns";
import { jwtDecode } from "jwt-decode";

type AuthToken = string & { __tag: "AuthToken" };

function asAuthToken(token: string): AuthToken {
  return token as AuthToken;
}

type CubeConfig = {
  readonly token: AuthToken;
  readonly endpoint: string;
};

const cubeDevTags = createTagTypes({
  tagPrefix: "CubeDev",
  tags: ["authToken"],
});

let expirationTimeout: NodeJS.Timeout | null = null;

const endpoints = simplicateApiV2.enhanceEndpoints({ addTagTypes: Object.values(cubeDevTags) }).injectEndpoints({
  endpoints: (builder) => ({
    getCubeConfig: builder.query<CubeConfig, void>({
      query: () => `cubeDev/jwt`,
      onQueryStarted: (_, { dispatch, queryFulfilled, ...queryLifeCycle }) => {
        const getCacheEntry = () => queryLifeCycle.getCacheEntry();

        // Query is (re)started, so it's safe to clear the expiration timeout
        if (expirationTimeout) {
          clearTimeout(expirationTimeout);
          expirationTimeout = null;
        }

        void queryFulfilled.then(getCacheEntry).then((entry) => {
          if (!entry || !entry.data) {
            return;
          }

          const token = entry.data.token;
          const decodedToken = jwtDecode<{ exp: number }>(token);
          const expirationDate = new Date(decodedToken.exp * 1_000);
          const timeUntilExpiration = differenceInMilliseconds(expirationDate, new Date());
          const desiredRefreshTime = timeUntilExpiration - 10_000; // subtract 10 seconds

          expirationTimeout = setTimeout(() => {
            dispatch({ type: "apiV2/invalidateTags", payload: [cubeDevTags.authToken] });
          }, desiredRefreshTime);
        });
      },
      transformResponse: (response: { data: { jwt: string; endpoint: string } }) => ({
        token: asAuthToken(response.data.jwt),
        endpoint: response.data.endpoint,
      }),
      providesTags: [cubeDevTags.authToken],
    }),
  }),
});

export const { useGetCubeConfigQuery } = endpoints;
