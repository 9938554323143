import { UniqueComponentId } from "primereact/utils";
import { cloneElement, isValidElement, ReactElement, useMemo } from "react";
import { ColumnProps } from "./Column/Column";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used
import styles from "./Table.module.scss";

type TheadProps = {
  width: string;
  children: Required<
    ReactElement<ColumnProps<Record<string, never>>> | ReactElement<ColumnProps<Record<string, never>>>[]
  >;
};

export const CustomThead = ({ width, children }: TheadProps) => {
  const childrenArray = useMemo(() => {
    return Array.isArray(children) ? children : [children];
  }, [children]);

  const memoizedTemplateColumns = useMemo(() => {
    return childrenArray
      .map((child) => {
        if (child.props.hidden) return "";

        const childWidth = child.props.width !== "dynamic" ? child.props.width ?? "auto" : "auto";

        return childWidth;
      })
      .join(" ");
  }, [childrenArray]);

  return (
    <div
      className={styles.stickyHeader}
      style={{
        display: "grid",
        gridTemplateColumns: memoizedTemplateColumns,
        width,
      }}
    >
      {childrenArray?.map((child, idx) => {
        if (!child || child.props.hidden) return null;

        const header = child.props.header;

        if (!header) return null;

        /* istanbul ignore next - exclude react build-in functions, valid element condition is included in test */
        return (
          <div
            key={UniqueComponentId()}
            className={styles.cell}
            style={{ justifySelf: idx == 0 || child.props.align === "left" ? "start" : "var(--justify-th-self, end)" }}
          >
            {
              // eslint-disable-next-line sonarjs/no-nested-conditional -- nested condition is needed
              typeof header === "string" ? header : isValidElement(header) ? cloneElement(header, { ...header }) : null
            }
          </div>
        );
      })}
    </div>
  );
};
