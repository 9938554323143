import { BackButtonBehavior } from "@simplicate/top-bar";
import { useLocation } from "react-router-dom";

export const RestoreURLFragmentBackButtonBehavior: BackButtonBehavior = () => {
  const { pathname } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const modelValue = urlParams.get("model")?.toLocaleLowerCase();

  const pathnameParts = pathname.split("/");

  pathnameParts.pop();

  return {
    target: new URL(
      modelValue ? `${pathnameParts.join("/")}#${modelValue}` : pathnameParts.join("/"),
      window.location.origin,
    ),
  };
};
