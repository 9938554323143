import { GroupedServicesManager } from "@simplicate/grouped-services-manager";
import { useTranslation } from "@simplicate/translations";
import { Page, Button, Dropdown, ListItem, List, PageHeader, Icon, showToast } from "@simplicate/ui";
import { lightColorIconInvert } from "@simplicate-software/design-tokens";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AddServiceGroupModal, EditServiceGroupModal } from "./components";
import { LoadingFallback } from "./components/LoadingFallback";
import { useSalesServicesPageData } from "./SalesServicesPage.hooks";
import styles from "./SalesServicesPage.module.scss";

export const SalesServicesPage = () => {
  const { id: saleId } = useParams<{ id: string }>();
  const {
    treeData,
    setAndSaveTreeData,
    rootId,
    groupServicesByInvoiceMethod,
    serviceGroupInEdit,
    isGetSalesError,
    setServiceGroupInEdit,
    isAutoGroupServicesSuccess,
    loadingMutation,
    loadingText,
  } = useSalesServicesPageData(saleId);

  const { t } = useTranslation("sales_services");

  const [modalOpen, setModalOpen] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  if (!treeData) {
    if (isGetSalesError) {
      showToast({
        message: t("service_data_fetch_failed"),
        type: "error",
      });

      return null;
    }

    return <LoadingFallback />;
  }

  const shouldShowLoadingFallback = () => {
    return loadingMutation?.isAutoGroupServicesLoading && !isAutoGroupServicesSuccess ? (
      <LoadingFallback text={loadingText} />
    ) : null;
  };

  return (
    <>
      {shouldShowLoadingFallback()}
      <Page>
        <PageHeader title={t("services_page_title")}>
          <div className={styles.buttonDropdownWrapper}>
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              {t("add_services_dropdown")}
              <Icon icon="chevronDown" color={lightColorIconInvert} />
            </Button>

            {dropdownOpen && (
              <div className={styles.dropdownContainer}>
                <Dropdown
                  isOpen={dropdownOpen}
                  width="auto"
                  variant="clean"
                  onClickOutside={/* istanbul ignore next */ () => setDropdownOpen(false)}
                >
                  <List>
                    <ListItem>
                      <Button variant="invisible" to={`/sales/${saleId}/services/new`}>
                        {t("add_service_button")}
                      </Button>
                    </ListItem>
                    <ListItem>
                      <Button
                        variant="invisible"
                        onClick={() => {
                          setModalOpen(true);
                          setDropdownOpen(false);
                        }}
                      >
                        {t("add_services_group_button")}
                      </Button>
                    </ListItem>
                  </List>
                </Dropdown>
              </div>
            )}
          </div>
          {groupServicesByInvoiceMethod && (
            <div className={styles.pageHeaderRight}>
              <Button variant="secondary" onClick={groupServicesByInvoiceMethod}>
                {t("group_by_invoice_method")}
                <Icon icon="wand" />
              </Button>
            </div>
          )}
        </PageHeader>

        {treeData && <GroupedServicesManager treeData={treeData} onChangeTree={setAndSaveTreeData} rootId={rootId} />}

        {serviceGroupInEdit && (
          <EditServiceGroupModal
            serviceGroup={serviceGroupInEdit}
            onClose={/* istanbul ignore next */ () => setServiceGroupInEdit(undefined)}
            key={serviceGroupInEdit.id}
          />
        )}

        {saleId && (
          <AddServiceGroupModal saleId={saleId} isOpen={modalOpen} onClose={() => setModalOpen(false)} key={saleId} />
        )}
      </Page>
    </>
  );
};
