import { colorCommonGrey200 } from "@simplicate-software/design-tokens";
import { CSSProperties } from "react";
import { getContrastColor, capNumber } from "../../utils";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used
import styles from "./Counter.module.scss";

const COLOR_BACKGROUND = "--background" as keyof CSSProperties;
const COLOR = "--color" as keyof CSSProperties;

type CounterProps = {
  background?: string;
  value?: number | string;
  variant?: "default" | "light";
  size?: "medium" | "small";
  testId?: string;
  renderHiddenPlaceholder?: boolean;

  /**
   * Maximum value to be displayed. If the value exceeds the maximum, the maximum value will be displayed with a "+" sign.
   * If set to false, the value will be displayed as is.
   */
  max?: number | false;
};

export const Counter = ({
  value,
  background = colorCommonGrey200,
  size = "small",
  variant = "default",
  testId,
  max = 99,
  renderHiddenPlaceholder = false,
}: CounterProps) => {
  const textColor = getContrastColor(background);

  if (max !== false) {
    const intValue = parseInt(value as string);

    if (!isNaN(intValue)) {
      value = capNumber(intValue, max);
    } else {
      value = undefined;
    }
  }

  if (!value) {
    return renderHiddenPlaceholder ? <span className={styles.counter} /> : null;
  }

  return (
    <span
      className={`${styles.counter} ${styles[variant]} ${styles[size]}`}
      style={{ [COLOR]: textColor, [COLOR_BACKGROUND]: background }}
      data-testid={testId}
    >
      {value}
    </span>
  );
};
