import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Checkbox.module.scss";

type CheckboxProps = PropsWithChildren<{
  name?: string;
  label?: string;
  onChange?: (newValue: boolean) => void;
  value: boolean | "indeterminate";
  disabled?: boolean;
  testId?: string;
}>;

export const Checkbox = ({ name, label, disabled, onChange, value, testId, children }: CheckboxProps) => {
  const hasLabel = label !== undefined || children !== undefined;

  return (
    <div className={classNames(styles.checkbox, disabled && styles.disabled, hasLabel && styles.hasLabel)}>
      <input
        id={name}
        name={name}
        type="checkbox"
        className={styles.input}
        onChange={(event) => onChange?.(event.target.checked)}
        checked={value === "indeterminate" ? false : value}
        disabled={disabled}
        ref={(input) => {
          if (input) {
            input.indeterminate = value === "indeterminate";
          }
        }}
        data-testid={testId}
      />
      {hasLabel && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {children}
        </label>
      )}
    </div>
  );
};
