import { skipToken, useGetPersonQuery } from "@simplicate/api-client";
import { PageHeader } from "@simplicate/top-bar";
import { useTranslation } from "@simplicate/translations";
import { Tag } from "@simplicate/ui";
import { useParams } from "react-router-dom";

export const PersonSubHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("top_bar");

  const { data: person } = useGetPersonQuery(id ?? skipToken, { refetchOnMountOrArgChange: true });

  if (!person) return null;

  const personName = person.data.full_name || "";
  const relationTypeLabel = person.data.relation_type?.label;
  const relationTypeColor = person.data.relation_type?.color;
  const personIsActive = person.data.is_active;
  const relationNumber = person.data.relation_number;

  const title = relationNumber ? `${relationNumber}. ${personName}` : personName;

  return (
    <PageHeader title={title}>
      {relationTypeLabel && (
        <Tag text={relationTypeLabel} color={relationTypeColor} testId="relation-type" size="small" variant="color" />
      )}
      {!personIsActive && <Tag text={t("inactive")} testId="person-type" size="small" />}
      <Tag text={t("person")} testId="person-type" size="small" />
    </PageHeader>
  );
};
