import { invalidateTagsV2, HEADER_DATA_TAG, LOCALE_DATA_TAG } from "@simplicate/api-client";
import { useAppSelector, useAppDispatch, postIframeMessage } from "@simplicate/state";
import { Button, Footer } from "@simplicate/ui";
import { useLocation } from "react-router-dom";
import { getVariant } from "./getVariant";

const CHANGE_LANGUAGE_PATH = "/dashboard/user/basic-info";
const INVALIDATION_DELAY = 500;

export const IFrameFooter = () => {
  const { latestMessages } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.iframe);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const buttonConfigs = latestMessages?.SELECT_BUTTONS?.payload.buttons ?? [];

  const handleClick = (eventName: string) => {
    dispatch(postIframeMessage({ eventName }));

    if (eventName.toLowerCase().includes("save")) {
      const tagsToInvalidate: Parameters<typeof invalidateTagsV2>[0] = [HEADER_DATA_TAG];

      // Iframes do not send an event when the language is changed. The legacy codebase simply waited
      // 3 seconds if and when the save button was clicked on this page only to trigger a hard page
      // reload. For now, this is a replication of that behaviour. A better solution would be to
      // introduce a new message type specifically for language changes, or rebuild the page in the
      // modernised codebase.
      // TODO: Remove this once the language change is handled in a different way
      if (location.pathname === CHANGE_LANGUAGE_PATH) {
        tagsToInvalidate.push(LOCALE_DATA_TAG);
      }

      setTimeout(() => dispatch(invalidateTagsV2(tagsToInvalidate)), INVALIDATION_DELAY);
    }
  };

  // TODO: Change the button colors in the backend to conform to the new design and remove the getVariant function.
  //The getVariant function only works when iFrameMessage(ButtonConfigs) has 3 buttons.
  //Therefore, we added additional color/variant configurations for when `ButtonConfig` has 2 buttons or 1 button as a temporary fix.
  if (buttonConfigs.length === 0) {
    return null;
  }
  if (buttonConfigs.length === 1) {
    return (
      <Footer>
        {buttonConfigs.map(({ title, eventName, color, disabled }) => {
          return (
            <Button
              key={eventName}
              variant={color === "green" ? "primary" : "secondary"}
              onClick={() => handleClick(eventName)}
              disabled={disabled}
            >
              {title}
            </Button>
          );
        })}
      </Footer>
    );
  } else {
    const buttonsReversed = buttonConfigs.slice().reverse();

    return (
      <Footer>
        {buttonsReversed.map(({ title, eventName, color, disabled }) => {
          return (
            <Button
              key={eventName}
              // eslint-disable-next-line sonarjs/no-nested-conditional -- This is a temporary fix and should be removed once the backend has been updated
              variant={buttonConfigs.length === 2 ? (color === "green" ? "primary" : "secondary") : getVariant(color)}
              onClick={() => handleClick(eventName)}
              disabled={disabled}
              testId={`${color}-button`}
            >
              {title}
            </Button>
          );
        })}
      </Footer>
    );
  }
};
