import { ReactNode } from "react";
import { SupportedFlags, useFeatureFlag } from "../useFeatureFlag";

type FeatureFlagSwitchProps = {
  featureFlag: SupportedFlags;
  ifEnabled: ReactNode;
  ifDisabled: ReactNode;
};

export const FeatureFlagSwitch = ({ featureFlag, ifEnabled, ifDisabled }: FeatureFlagSwitchProps) => {
  const { enabled, isFetching } = useFeatureFlag(featureFlag);

  if (isFetching) {
    return null;
  }

  return enabled ? ifEnabled : ifDisabled;
};
