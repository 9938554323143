import { Big } from "@simplicate/api-client";
import { useNumericFormatter } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Alert, Link } from "@simplicate/ui";
import styles from "./ProjectServiceTotalInfo.module.scss";
import type { MoneyInForm } from "./types";

type ProjectServiceTotalInfoProps = {
  calculatedTotal: Big;
  invoicePrice: MoneyInForm | undefined;
  setInvoicePrice: (value: Big) => void;
};

export const ProjectServiceTotalInfo = ({
  calculatedTotal,
  invoicePrice,
  setInvoicePrice,
}: ProjectServiceTotalInfoProps) => {
  const { t } = useTranslation("project_services");
  const formattedCalculatedTotal = useNumericFormatter(calculatedTotal?.toString());

  if (!invoicePrice?.amount) {
    return;
  }

  if (calculatedTotal.cmp(invoicePrice.amount) === 0) {
    return;
  }

  return (
    <Alert type="info">
      <div className={styles.totalInfo}>
        <span>
          {t("invoice_price_differs_from_calculated", {
            value: formattedCalculatedTotal,
          })}
        </span>
        <Link testId="reset-invoice-price-button" onClick={() => setInvoicePrice(calculatedTotal)}>
          {t("reset_invoice_price")}
        </Link>
      </div>
    </Alert>
  );
};
