import { Button, Input, Tag, Icon } from "@simplicate/ui";
import { lightColorIconSuccess } from "@simplicate-software/design-tokens";
import { useEffect, useRef } from "react";
import { useCostTypeMutationForm } from "../CostTypeMutationForm";
import styles from "./CostTypeNameCell.module.scss";

type CostTypeNameCellProps = {
  label: string;
  defaultCostTypeName: string;
  costTypeId: string;
  inEditMode: boolean;
  onConfirmEdit: (newLabel: string) => void;
  onCancelEdit: () => void;
  disabled?: boolean;
};

export const CostTypeNameCell = ({
  label,
  defaultCostTypeName,
  costTypeId,
  inEditMode,
  onConfirmEdit,
  onCancelEdit,
  disabled = false,
}: CostTypeNameCellProps) => {
  const labelInputRef = useRef<HTMLInputElement>(null);

  const { values, touched, errors, setLabel, isValid, submitForm } = useCostTypeMutationForm({
    onSubmit: /* istanbul ignore next - submitForm function is mocked in tests */ (label) =>
      label && onConfirmEdit(label),
    initialValues: {
      label,
      costTypeId,
    },
  });

  useEffect(() => {
    if (!inEditMode) {
      return;
    }

    labelInputRef.current?.focus();
  }, [inEditMode, labelInputRef]);

  useEffect(() => {
    if (inEditMode) {
      return;
    }

    void setLabel(label);
  }, [inEditMode, setLabel, label]);

  return (
    <div className={styles.nameColumnBody}>
      <Tag text={defaultCostTypeName} />
      {!inEditMode && <span>{label}</span>}
      {inEditMode && (
        <div className={styles.input}>
          <Input
            ref={labelInputRef}
            testId="cost-type-edit-label"
            type="text"
            name="cost-type-edit-label"
            size="small"
            value={values.label ?? ""}
            hasError={touched.label && errors.label !== undefined}
            onChange={(event) => void setLabel(event.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                void submitForm();

                return;
              }

              if (e.key === "Escape") {
                e.stopPropagation();
                onCancelEdit();
              }
            }}
            disabled={disabled}
          />
        </div>
      )}
      {inEditMode && (
        <Button
          testId="confirm-new-label"
          variant="secondary"
          onClick={() => void submitForm()}
          disabled={disabled || !isValid}
          hasInlinePadding={false}
        >
          <Icon icon="check" color={lightColorIconSuccess} />
        </Button>
      )}
      {inEditMode && (
        <Button
          testId="cancel-new-label"
          hasInlinePadding={false}
          variant="secondary"
          onClick={onCancelEdit}
          disabled={disabled}
        >
          <Icon icon="times" />
        </Button>
      )}
    </div>
  );
};
