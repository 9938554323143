import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { CubeDimension, Filter } from "../../types";

type SearchParamToDimension = {
  recordKey: CubeDimension;
  searchParam: string;
};

export type Mapping = {
  label: SearchParamToDimension;
  value: SearchParamToDimension;
};

export const useSearchParamFilters = (mapping: readonly Mapping[]) => {
  const [params] = useSearchParams();

  const filters: Filter[] = useMemo(
    () =>
      mapping
        .map((config) => {
          const label = params.get(config.label.searchParam);
          const value = params.get(config.value.searchParam);

          if (!label || !value) {
            return undefined;
          }

          return {
            valueDimension: config.value.recordKey,
            labelDimension: config.label.recordKey,
            label,
            value,
          };
        })
        .filter((filter): filter is Filter => filter !== undefined),
    [mapping, params],
  );

  return filters;
};
