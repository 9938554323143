import { useAppSelector } from "@simplicate/state";
import { IFrame } from "@simplicate/ui";
import classNames from "classnames";
import { SyntheticEvent, useRef } from "react";
import { setWindowLocation } from "../../routing/utils";
import styles from "./IFrameEnvironment.module.scss";
import { IFrameFooter } from "./IFrameFooter";
import { useProjectReportCache } from "./ProjectReportCache";
import { useIFrameCommunication } from "./useIFrameCommunication";
import { useLocationCorrection } from "./useLocationCorrection";
import { useNavigationIntercept } from "./useNavigationIntercept";

type IFrameEnvironmentProps = {
  src: string;

  /**
   * When the iframe initiates a redirect, either through iframe message, or through normal browser navigation events.
   * And that redirect points to this target (after url conversion), the redirect will not happen. Instead, the location
   * of the top-level browser will be forcibly updated to the target location.
   *
   * NOTE: this is band-aid fix for SIM-36666
   * TODO: remove this
   *
   * @deprecated -- do not use this */
  expectedRedirect?: string;

  /**
   * Default: <pre>true</pre>
   *
   * When set to <pre>false</pre>, the iframe will not be remounted when the search params of the iframe location
   * change. Which means that the top level document will only respond to redirect requests by updating the top level
   * location url. As opposed to forcing the iframe to be remounted resulting in a forced reload of the content (which
   * is desired behaviour).
   *
   * NOTE: this is band-aid fix for SIM-36710
   * TODO: remove this
   *
   * @deprecated -- do not use this */
  remountOnSearchChange?: boolean;
};

// todo: move this helper to the routing dir?
const redirectIfLogin = (location: URL) => {
  if (location?.pathname.includes("/site/")) {
    // Using the navigate function from react-router-dom will attempt in memory navigation, which will not work as the
    // login page is not a known route in the frontend.
    setWindowLocation(location.href);
  }
};

export const IFrameEnvironment = ({ src, expectedRedirect, remountOnSearchChange = true }: IFrameEnvironmentProps) => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const { latestMessages } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.iframe);
  const { showOverlay } = useAppSelector(/* istanbul ignore next */ ({ state }) => state.header);
  const { isLoading: identifierConversionPending, location } = useLocationCorrection(src);
  const {
    onUnload: unloadHandler,
    navigationIntercepted,
    navigate,
  } = useNavigationIntercept(iFrameRef, expectedRedirect);

  useIFrameCommunication(iFrameRef, navigate);

  useProjectReportCache(iFrameRef);

  const onIFrameLoad = (loadEvent: SyntheticEvent<HTMLIFrameElement, Event>) => {
    const eventTarget = loadEvent.currentTarget;
    const iFrameWindow = eventTarget.contentWindow;

    if (iFrameWindow) {
      const currentLocation = new URL(iFrameWindow.location.href);

      redirectIfLogin(currentLocation);

      iFrameWindow.removeEventListener("unload", unloadHandler);
      iFrameWindow.addEventListener("unload", unloadHandler);

      /* istanbul ignore next -- Some components rely on click events bubbling to the document element. */
      iFrameWindow.document.addEventListener("click", () => iFrameRef.current?.click());
      /* istanbul ignore next -- Some components rely on pointerup events bubbling to the document element. */
      iFrameWindow.document.addEventListener("pointerup", () => {
        iFrameRef.current?.dispatchEvent(
          new PointerEvent("pointerup", {
            bubbles: true,
          }),
        );
      });

      // The backend codebase uses the history API to navigate between pages and to update search params.
      // Even though the backend ALSO sends an iframe message requesting the frontend to navigate to a new page.
      // Using both methods within a single flow is quite superfluous, so we overwrite these functions such that unique events arrive only once.
      // TODO: remove this once this issue has been resolved.
      /* istanbul ignore next -- this is a temporary fix, we should remove this once the backend has been updated */
      iFrameWindow.history.pushState = (...args) => {
        console.log("push state", ...args);
      };
      /* istanbul ignore next -- this is a temporary fix, we should remove this once the backend has been updated */
      iFrameWindow.history.replaceState = (...args) => {
        console.log("replace state", ...args);
      };
    }
  };

  const hasFooter = (latestMessages?.SELECT_BUTTONS?.payload.buttons?.length ?? 0) > 0 && !showOverlay;

  return (
    <>
      <div
        className={classNames(
          styles.iframeContainer,
          hasFooter && styles.withFooter,
          (navigationIntercepted || identifierConversionPending) && styles.hidden,
        )}
        data-testid="iframe-environment"
      >
        <IFrame
          key={src}
          ref={iFrameRef}
          src={location}
          title="GeneralIframe"
          onLoad={onIFrameLoad}
          testId="iframe"
          remountOnSearchChange={remountOnSearchChange}
        />
      </div>
      {hasFooter && (
        <div className={styles.footer}>
          <IFrameFooter />
        </div>
      )}
    </>
  );
};
