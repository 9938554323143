import styles from "./FormikErrors.module.scss";

type FormikErrorProps = {
  error: string | undefined;
  touched: boolean | undefined;
};

export const FormikErrors = ({ touched, error }: FormikErrorProps) => {
  if (error && touched) {
    return <span className={styles.invalid}>{error}</span>;
  }

  return null;
};
