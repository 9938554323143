import { useEnvironmentSetting } from "@simplicate/api-client";
import getSymbolFromCurrency from "currency-symbol-map";
import { NumberFormat, NumberFormatProps } from "./NumberFormat";

type CurrencyFormatProps = NumberFormatProps & { ccy?: string };

const defaultConfig = {
  decimalScale: 2,
  fixedDecimalScale: true,
  type: "text",
} satisfies NumberFormatProps;

const getCurrencyConfig = (
  ccy: string | undefined,
  displayType: NumberFormatProps["displayType"],
): Partial<NumberFormatProps> => {
  const symbol = ccy ? getSymbolFromCurrency(ccy) : "";

  return displayType === "input" ? { leftComponent: <span>{symbol}</span> } : { prefix: `${symbol} ` };
};

export const CurrencyFormat = ({ ccy, ...props }: CurrencyFormatProps) => {
  const environmentCcy = useEnvironmentSetting("general", "ccy")?.value;

  const configuredProps = {
    ...defaultConfig,
    ...getCurrencyConfig(ccy ?? environmentCcy, props.displayType),
    ...props,
  };

  return <NumberFormat {...configuredProps} />;
};
