import classNames from "classnames";
import { Checkbox } from "../Checkbox";
import { Icon } from "../Icon";
import { useDraggable, useSelectable } from "./hooks";
import { SortableListRecord } from "./SortableList.types";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used
import styles from "./SortableListItem.module.scss";

type SortableListItemProps<T extends SortableListRecord> = {
  renderItem: (item: T) => React.ReactNode;
  value: T;
};

export const SortableListItem = <T extends SortableListRecord>({ renderItem, value }: SortableListItemProps<T>) => {
  const { isSelectable, checkboxProps } = useSelectable(value);
  const { isSortable, isDragging, isOver, isOnTopHalf, ref } = useDraggable(value);

  return (
    <li
      className={classNames(
        styles.listItem,
        isDragging && styles.isDragging,
        isOver && styles.isOver,
        isOnTopHalf && styles.isOnTopHalf,
      )}
      ref={ref}
    >
      {isSortable && (
        <div className={styles.dragHandle}>
          <Icon icon="grip" />
        </div>
      )}
      {isSelectable ? (
        <Checkbox {...checkboxProps} name={value.id}>
          {renderItem(value)}
        </Checkbox>
      ) : (
        renderItem(value)
      )}
    </li>
  );
};
