/* istanbul ignore file */
import { LocaleProvider } from "@simplicate/localization";
import { PrimeReactProvider } from "@simplicate/primereact";
import { DataProvider } from "@simplicate/state";
import { TranslationsProvider } from "@simplicate/translations";
import { ComponentAndProps, ComposeComponents, MessageBus } from "@simplicate/utils";
import {
  AnalyticsProvider,
  ErrorBoundary,
  LoggedInGuard,
  SimplicateIntercomProvider,
  LocaleAwareProvider,
} from "../components";
import { ErrorBoundaryActivator } from "../components/ErrorBoundary";
import { MainRouterProvider } from "../routing";
import "./App.scss";
import { usePreloadLazyComponents } from "./usePreloadLazyComponents";

MessageBus.init();

const contextProviders = [
  [ErrorBoundary],
  [DataProvider],
  [LoggedInGuard],
  [AnalyticsProvider],
  [LocaleProvider],
  [PrimeReactProvider],
  [TranslationsProvider],
  [LocaleAwareProvider],
  [SimplicateIntercomProvider],
] satisfies ComponentAndProps[];

export const App = () => {
  usePreloadLazyComponents();

  return (
    <ComposeComponents items={contextProviders}>
      <MainRouterProvider />
      <ErrorBoundaryActivator />
    </ComposeComponents>
  );
};
