import classNames from "classnames";
import { InputHTMLAttributes, forwardRef, useId } from "react";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[size]
import styles from "./Input.module.scss";

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "id" | "size"> & {
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  hasError?: boolean;
  label?: string;
  type: string;
  disabled?: boolean;
  name?: string;
  testId?: string;
  size?: "normal" | "small";
  textAlign?: "left" | "right";
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    leftComponent,
    rightComponent,
    hasError,
    testId,
    label,
    disabled,
    name,
    value = "",
    size = "normal",
    textAlign = "left",
    ...inputProps
  }: InputProps,
  ref,
) {
  const id = useId();

  return (
    <div data-testid={testId} className={classNames(styles.wrapper, disabled && styles.disabled)}>
      {label && (
        <label htmlFor={id} className={classNames(styles.label)}>
          {label}
        </label>
      )}
      <div
        data-testid={`inputWrapper-${testId}`}
        className={classNames(styles.inputWrapper, hasError && styles.error, styles[size])}
      >
        {leftComponent && (
          <div className={styles.leftComponent} data-testid={`leftComponent-${testId}`}>
            {leftComponent}
          </div>
        )}
        <input
          ref={ref}
          data-testid={testId && `input-${testId}`}
          className={classNames(styles.input, styles[textAlign])}
          disabled={disabled}
          name={name}
          id={id}
          value={value}
          {...inputProps}
        />
        {rightComponent && (
          <div className={styles.rightComponent} data-testid={`rightComponent-${testId}`}>
            {rightComponent}
          </div>
        )}
      </div>
    </div>
  );
});
