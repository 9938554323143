import { useCallback, useMemo, useRef, useState, KeyboardEvent } from "react";

const OPEN_KEYS = ["Enter", " ", "ArrowDown"];

export const useDropdownState = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const firstFocusable = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = useCallback(() => setIsOpen((prev) => !prev), []);
  const focusFirstInteractable = useCallback(() => setTimeout(() => firstFocusable.current?.focus()), []);
  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }

      if (OPEN_KEYS.includes(event.key) && !isOpen) {
        setIsOpen(() => true);
        focusFirstInteractable();
      }
    },
    [focusFirstInteractable, isOpen],
  );
  const closeDropdown = useCallback(() => setIsOpen(false), []);
  const closeAndFocusOpenButton = useCallback(() => {
    closeDropdown();
    buttonRef.current?.focus();
  }, [closeDropdown]);

  return useMemo(
    () => ({
      closeDropdown: closeAndFocusOpenButton,
      focusTrapRef: firstFocusable,
      openButtonProps: {
        ref: buttonRef,
        onClick: toggleDropdown,
        onKeyDown: handleKeyboardEvent,
      },
      popoverProps: {
        anchor: buttonRef,
        isVisible: isOpen,
      },
      dropdownProps: {
        isOpen,
        onClose: closeDropdown,
        onClickOutside: closeDropdown,
        onKeyboardDismiss: closeAndFocusOpenButton,
        onKeyupOutside: focusFirstInteractable,
        onFocusLoss: focusFirstInteractable,
      },
    }),
    [closeAndFocusOpenButton, closeDropdown, focusFirstInteractable, handleKeyboardEvent, isOpen, toggleDropdown],
  );
};
