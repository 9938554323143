import { simplicateApiV2 } from "@simplicate/api-client";
import {
  PostIFrameMessage,
  setPostIframeMessageHandler,
  useAppDispatch,
  iframeMessageReceived,
  IframeEvent,
  clearMessages,
  IframeActionPayload,
} from "@simplicate/state";
import { RefObject, useEffect } from "react";

type navigateCallback = (location: string) => void;

const useIframeRedirect = (navigate: navigateCallback) => {
  return ({ url }: IframeActionPayload) => {
    if (!url) return;
    navigate(url);
  };
};

const useInvalidateTags = () => {
  const dispatch = useAppDispatch();

  return ({ tags }: IframeActionPayload) => {
    if (!tags) return;
    dispatch(simplicateApiV2.util.invalidateTags(tags));
  };
};

/* istanbul ignore next -- these events do not work properly in jest, this should be tested through other means */
export const useIFrameCommunication = (iFrameRef: RefObject<HTMLIFrameElement>, navigate: navigateCallback) => {
  const dispatch = useAppDispatch();
  const iframeRedirect = useIframeRedirect(navigate);
  const invalidateTags = useInvalidateTags();

  useEffect(() => {
    const postMessageToIframe = (message: PostIFrameMessage) => {
      iFrameRef.current?.contentWindow?.postMessage(JSON.stringify(message));
    };

    dispatch(setPostIframeMessageHandler(postMessageToIframe));
  }, [dispatch, iFrameRef]);

  useEffect(() => {
    const onMessageReceived = (event: Event) => {
      // @ts-expect-error -- detail does not exist on default Event type. This is not the default Event type though.
      const message = event.detail as IframeEvent;

      for (const { action, payload } of message.params) {
        if (action === "IFRAME_REDIRECT") iframeRedirect(payload);
        if (action === "IFRAME_INVALIDATE_TAGS") invalidateTags(payload);
      }

      dispatch(iframeMessageReceived(message));
    };

    window.addEventListener("simplicateIframeMessage", onMessageReceived);

    return () => {
      window.removeEventListener("simplicateIframeMessage", onMessageReceived);
    };
  }, [iFrameRef, invalidateTags, iframeRedirect, dispatch]);

  useEffect(() => {
    // This cleanup effects clears all messages when the component is unmounted, i.e. the iframe is destroyed.
    return () => {
      dispatch(clearMessages());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this is a cleanup effect it needs no dependencies
  }, []);
};
