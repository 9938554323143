import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./GroupedControlsItem.module.scss";

type GroupedControlsItemProps = PropsWithChildren & {
  testId?: string;
};

export const GroupedControlsItem = ({ testId, children }: GroupedControlsItemProps) => {
  return (
    <div className={classNames(styles.item)} data-testid={testId}>
      {children}
    </div>
  );
};
