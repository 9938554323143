import classNames from "classnames";
import { Clickable } from "../Clickable";
import { IconName, Icon } from "../Icon";
import styles from "./SegmentedControlButton.module.scss";

type SegmentedControlButtonProps = {
  icon?: IconName;
  text?: string;
  disabled?: boolean;
  to?: string;
  selected?: boolean;
  onClick?: () => void;
  testId?: string;
};

export const SegmentedControlButton = ({
  icon,
  text,
  disabled,
  to,
  selected = true,
  onClick,
  testId,
}: SegmentedControlButtonProps) => {
  return (
    <Clickable
      className={classNames(styles.button, !selected && styles.deselected)}
      key={text ?? icon}
      onClick={onClick}
      to={to}
      disabled={disabled}
      data-testid={`${testId}-segmented-button`}
    >
      {icon && <Icon fixedWidth className={styles.icon} icon={icon} testId={`${testId}-icon`} />}
      {text && <span className={styles.text}>{text}</span>}
    </Clickable>
  );
};
