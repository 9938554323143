import { useGetExportsQuery } from "@simplicate/api-client";
import { useAppSelector } from "@simplicate/state";
import { MessageBus } from "@simplicate/utils";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";

const EXPORT_RUNNING_STATUS_CODES: (string | undefined)[] = ["export_in_progress", "export_already_running"];
const POLLING_INTERVAL = 30 * 1_000; // 30 seconds

// eslint-disable-next-line complexity -- TODO: Refactor to reduce complexity
export function useExportsButton() {
  const { IFRAME_EXPORT_STATE } = useAppSelector(/*istanbul ignore next*/ ({ state }) => state.iframe.latestMessages);
  const [hasInProgressExport, setHasInProgressExport] = useState<boolean>(false);
  const [displayButton, setDisplayButton] = useState<boolean>(false);
  const { data: exports, refetch } = useGetExportsQuery(undefined, {
    pollingInterval: hasInProgressExport ? POLLING_INTERVAL : 0,
  });

  useEffect(() => {
    const isExportInProgress = (exports?.metadata?.countInProgress ?? 0) > 0 || (exports?.metadata?.countOpen ?? 0) > 0;

    setHasInProgressExport(isExportInProgress);
  }, [exports?.metadata?.countInProgress, exports?.metadata?.countOpen]);

  useEffect(() => MessageBus.subscribe("export", () => void refetch()), [refetch]);

  useEffect(() => {
    if (EXPORT_RUNNING_STATUS_CODES.includes(IFRAME_EXPORT_STATE?.payload.statusCode)) {
      void refetch();
    }
    // Trigger also when the timestamp changes, as each of these messages could be identical payload-wise.
  }, [IFRAME_EXPORT_STATE?.payload.statusCode, IFRAME_EXPORT_STATE?.timestamp, refetch]);

  useLayoutEffect(() => {
    if ((exports?.metadata?.countTotal ?? 0) > 0) {
      setDisplayButton(true);
    }
  }, [exports?.metadata?.countTotal]);

  const handleClose = useCallback(() => {
    setDisplayButton(exports?.metadata?.countTotal !== 0);
  }, [exports?.metadata?.countTotal]);

  return {
    displayButton,
    handleClose,
    isExportInProgress: hasInProgressExport,
    badgeCount: exports?.metadata?.countTotal,
  };
}
