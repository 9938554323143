import { Navigate } from "react-router-dom";
import { IFrameEnvironment, LegacyWebComponent, HrmTabs, HrmDetailsPageTabs, Timetable } from "../../components";
import { EmployeeSubHeader } from "../../components/NavigationWrapper/SubHeaders";
import { RouteObject } from "../types";

export const hrmRoutes: RouteObject<"hrm">[] = [
  {
    path: "hrm",
    handle: {
      headerTitle: "module_hrm",
      theme: "hrm",
      permissions: [
        {
          module_key: "hrm",
          right_key: "view",
        },
      ],
    },
    children: [
      {
        index: true,
        element: <Navigate to="employees" replace />,
      },
      {
        path: "employees",
        element: <IFrameEnvironment src="/v1/hrm" />,
        handle: { tabsComponent: HrmTabs },
      },
      {
        path: "all-employees",
        element: <IFrameEnvironment src="/v1/hrm?tab=allEmployees" />,
        handle: {
          tabsComponent: HrmTabs,
          permissions: [
            {
              module_key: "hrm",
              right_key: "viewall",
            },
          ],
        },
      },
      {
        path: "reporting",
        handle: {
          permissions: [
            {
              module_key: "hrm",
              right_key: "rapportage",
            },
          ],
        },
        children: [
          {
            index: true,
            element: <Navigate to="leave" replace />,
          },
          {
            path: "leave",
            element: <IFrameEnvironment src="/v1/reporting/leave" remountOnSearchChange={false} />,
            handle: {
              headerTitle: "hrm_reporting_leave",
            },
          },
          {
            path: "absence",
            element: <IFrameEnvironment src="/v1/reporting/absence" remountOnSearchChange={false} />,
            handle: {
              headerTitle: "hrm_reporting_absence",
            },
          },
        ],
      },
      {
        path: ":id",
        handle: {
          backNavigationPath: "/hrm/employees",
          subHeaderComponent: EmployeeSubHeader,
          tabsComponent: HrmDetailsPageTabs,
        },
        children: [
          {
            index: true,
            element: <Navigate to="overview" replace />,
          },
          {
            path: "overview",
            element: <LegacyWebComponent />,
          },
          {
            path: "personal-info",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=contact" />,
          },
          {
            path: "employee",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=personalia" />,
          },
          {
            path: "user",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=user" />,
          },
          {
            path: "contract",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=contract" />,
          },
          {
            path: "timetable",
            element: <Timetable />,
          },
          {
            path: "leave",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=furlough_employee" />,
          },
          {
            path: "absence",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=absence" />,
          },
          {
            path: "tasks",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=tasks" />,
          },
          {
            path: "documents",
            element: <IFrameEnvironment src="/v1/hrm/employee?id=:id&tab=personnelFile" />,
          },
        ],
      },
    ],
  },
];
