import { SubscriptionCycle } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select } from "@simplicate/ui";
import { memo } from "react";

type SubscriptionCycleSelectProps = {
  value?: SubscriptionCycle;
  touched?: boolean;
  error: string | undefined;
  onSelect: (method: SubscriptionCycle) => void;
  disabled?: boolean;
  disabledTooltip?: string;
};

export const SubscriptionCycleSelect = memo(function SubscriptionCycleSelect({
  value,
  error,
  onSelect,
  disabled,
  touched = false,
}: SubscriptionCycleSelectProps) {
  const { t } = useTranslation("project_services");

  const options = [
    {
      value: "monthly",
      label: t("monthly"),
    },
    {
      value: "quarterly",
      label: t("quarterly"),
    },
    {
      value: "half_yearly",
      label: t("half_yearly"),
    },
    {
      value: "yearly",
      label: t("yearly"),
    },
  ];

  return (
    <Select
      testId="subscription_cycle_select"
      name="subscriptionCycle"
      label={t("subscription_cycle")}
      placeholder={t("subscription_cycle_placeholder")}
      emptyMessage={t("no_default_services")}
      value={value}
      options={options}
      onSelect={onSelect}
      invalid={touched && error !== undefined}
      disabled={disabled}
      filter={false}
    />
  );
});
