import { useGetInvoiceQuery, skipToken, DRAFT_INVOICE_STATUS_ID } from "@simplicate/api-client";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const InvoicesRedirectComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { data: invoice, isFetching } = useGetInvoiceQuery(id && id.length > 0 ? id : skipToken);
  const navigate = useNavigate();

  const invoiceIsDraft = invoice?.data.status.id === DRAFT_INVOICE_STATUS_ID;

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (invoiceIsDraft) {
      navigate(`/invoicing/${id}/draft-invoice`);
    } else {
      navigate(`/invoicing/${id}/invoice`);
    }
  }, [id, invoiceIsDraft, isFetching, navigate]);

  return null;
};
