import { eachDayOfInterval } from "date-fns";
import { useMemo, useState } from "react";
import { DateRange } from "./DateRangePicker.types";

export const useRangeHighlight = (range: DateRange) => {
  const [hoveredEndDate, setHoveredEndDate] = useState<Date | undefined>(undefined);

  const highlightRange = useMemo(() => {
    if (!range?.[0]) {
      return [];
    }

    const endDate = hoveredEndDate ?? range[1];

    if (!endDate) {
      return [];
    }

    return eachDayOfInterval({ start: range[0], end: endDate });
  }, [hoveredEndDate, range]);

  return {
    highlightRange,
    setHoveredEndDate,
    clearHoveredEndDate: () => setHoveredEndDate(undefined),
  };
};
