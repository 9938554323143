import { ConversionRecord } from "../types";
import { extractSelectedTab } from "./utils";

export const DashboardRouteConversions: ConversionRecord = {
  "/v1/dashboard/user": (location) => {
    const suffix =
      extractSelectedTab(location, {
        basic_info: "basic-info",
        calendar_integration: "calendar-integration",
        // eslint-disable-next-line sonarjs/no-hardcoded-passwords -- This is a route conversion
        changepassword: "change-password",
      }) || "basic-info";

    return {
      status: "complete",
      location: `/dashboard/user/${suffix}`,
    };
  },
};
