import { useTranslation } from "@simplicate/translations";
import { Icon } from "@simplicate/ui";
import { memo } from "react";
import { TopBarButton } from "../TopBarButton";
import { ExportDropdown } from "./ExportDropdown";
import { useExportsButton } from "./useExportsButton";

/**
 * Fetches the exports and, if there are any, will render the dropdown menu along with its button to open it.
 * Whenever the iframe sends a message that an export is in progress, it will refetch the exports.
 * When the dropdown is opened, it will refetch the exports and continue polling for changes.
 */
export const Exports = memo(function ExportsComponent() {
  const { t } = useTranslation("top_bar");
  const { displayButton, badgeCount, handleClose, isExportInProgress } = useExportsButton();

  if (displayButton) {
    return (
      <TopBarButton
        aria-label={t("label_export_button")}
        testId="top-bar-export-button"
        dropdownComponent={<ExportDropdown />}
        title="Exports"
        badge={isExportInProgress ? <Icon icon="hourglass" /> : badgeCount}
        onDropdownClose={handleClose}
        dropdownHeight="limitedWithScroll"
      >
        <Icon icon="fileExport" />
      </TopBarButton>
    );
  }

  return null;
});
