declare global {
  type ActionButtonConfig = {
    text: string;
    variant: "primary" | "secondary";
    icon?: string;
    disabled?: boolean;
  } & (
    | {
        type: "button";
        eventName: string;
      }
    | {
        type: "link";
        isDownload?: true;
        href: string;
      }
  );

  type Messages = {
    // Existing messages are registered here.
    actionButtonEvent: { eventName: string };
    backdrop: { isVisible: boolean };
    blockNavigation: { block: boolean };
    export: { timestamp: number };
    header: { title?: string; theme?: string };
    mounted: { origin: "web-component" };
    navigation: { path: string; origin: "document-editor" | "react" | "web-component" };
    notification: {
      message: string;
      type?: "error" | "info" | "success" | "warning";
      toastId?: string;
      options?: {
        duration?: number;
        dismissable?: boolean;
      };
      error?: Error;
    };
    registerActionButton: ActionButtonConfig[];
  };

  type Topic = keyof Messages;
  type Payload<TTopic extends Topic> = Messages[TTopic];

  interface Window {
    messageBus: {
      /**
       * Publish a message to all subscribers of the topic.
       * @param topic The topic to publish to.
       * @param data The payload of the message you want to publish.
       */
      publish: <TTopic extends Topic>(topic: TTopic, data: Payload<TTopic>) => void;

      /**
       * Subscribe to a topic, upon publish the callback will be called with published payload.
       * @example useEffect(() => { return window.messageBus.subscribe("mounted", (data) => console.log(data.origin)); });
       * @param topic The topic to subscribe to.
       * @param callback The callback to call when a message is published on this topic.
       * @returns A clean-up function that removes the subscription.
       */
      subscribe: <TTopic extends Topic>(topic: TTopic, callback: (data: Payload<TTopic>) => void) => () => void;
    };

    showToast: (data: { text: string }) => void;
  }
}

type Subscription<TTopic extends Topic> = { key: string; callback: (payload: Payload<TTopic>) => void };
type MessageBusRegistry = Partial<{ [TTopic in Topic]: Subscription<TTopic>[] }>;

export class MessageBus {
  private static instance: MessageBus;
  private static get reference(): MessageBus {
    if (!this.instance) {
      this.instance = new MessageBus();
    }

    return this.instance;
  }

  private registry: MessageBusRegistry = {};
  private counter = 0;

  static init() {
    window.messageBus = MessageBus.reference;
    // Assign a show toast function to the window object so it can be called from the outside.
    // The resource planner uses this to show toasts.
    // TODO: Remove this once the resource planner no longer uses this.
    window.showToast = ({ text }: { text: string }) => MessageBus.publish("notification", { message: text });
  }

  public subscribe<TTopic extends Topic>(topic: TTopic, callback: (payload: Payload<TTopic>) => void) {
    if (this.registry[topic] === undefined) {
      this.registry[topic] = [];
    }

    /* istanbul ignore next */
    const subscriberKey = `${topic}-${this.counter++}`;

    /* istanbul ignore next */
    this.registry[topic]?.push({
      key: subscriberKey,
      callback,
    });

    return (() => {
      /* istanbul ignore next */
      const index = this.registry[topic]?.findIndex((sub) => sub.key === subscriberKey);

      if (index !== undefined && index !== -1) {
        /* istanbul ignore next */
        this.registry[topic]?.splice(index, 1);
      }
    }).bind(this);
  }

  public publish<TTopic extends Topic>(topic: TTopic, payload: Payload<TTopic>) {
    /* istanbul ignore next */
    const registrations = this.registry[topic] ?? [];

    registrations.forEach(({ callback }) => callback(payload));
  }

  static subscribe<TTopic extends Topic>(topic: TTopic, callback: (payload: Payload<TTopic>) => void) {
    return this.reference.subscribe(topic, callback);
  }

  static publish<TTopic extends Topic>(topic: TTopic, payload: Payload<TTopic>) {
    return this.reference.publish(topic, payload);
  }

  /**
   * Clears all subscriptions.
   *
   * @deprecated This method is only intended for use in tests. Do not use in production code.
   */
  /* istanbul ignore next */
  public static UNSAFE_clear() {
    this.reference.registry = {};
  }
}
