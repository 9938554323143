import { skipToken } from "@simplicate/api-client";
import { useEffect } from "react";
import { useBuildProjectServiceQuery, BuiltProjectService } from "./data/endpoints";

type useBuildProjectServiceProps = {
  projectId: string | undefined;
  isEditingService: boolean;
  defaultServiceValue: string | undefined;
  setValuesFromProjectService: (projectService: BuiltProjectService) => void;
};

export const useBuildProjectService = ({
  defaultServiceValue,
  projectId,
  isEditingService,
  setValuesFromProjectService,
}: useBuildProjectServiceProps) => {
  const {
    data: projectService,
    isSuccess,
    isFetching,
  } = useBuildProjectServiceQuery(
    defaultServiceValue && projectId && !isEditingService
      ? { defaultServiceId: defaultServiceValue, projectId: projectId }
      : skipToken,
  );

  useEffect(() => {
    if (isFetching || !isSuccess || projectService == undefined) {
      return;
    }

    setValuesFromProjectService(projectService);
  }, [isSuccess, projectService, setValuesFromProjectService, isFetching]);
};
