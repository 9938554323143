/* istanbul ignore file -- Due to document.querySelector being called, this is very very difficult to jest. */
import { useTranslation } from "@simplicate/translations";
import { useEffect } from "react";
import { useBlocker } from "react-router-dom";

type SimplicateIframeObject = HTMLIFrameElement & {
  contentWindow: {
    simplicate?: {
      change?: boolean;
    };
  } | null;
};

// TODO: Turn this into a proper modal component with options such as "discard and continue" or "save and continue"
export const useNavigationBlocker = () => {
  const { t } = useTranslation("general");
  const blocker = useBlocker(true);

  useEffect(() => {
    if (blocker.state === "blocked") {
      const iframeWindow = document.querySelector("iframe");

      if (iframeWindow && (iframeWindow as SimplicateIframeObject)?.contentWindow?.simplicate?.change === true) {
        const shouldContinue = window.confirm(t("navigation_with_unsaved_changes_confirmation"));

        if (!shouldContinue) {
          blocker.reset();

          return;
        }
      }

      blocker.proceed();
    }
  }, [blocker, t]);
};
