import { PropsWithChildren, createContext, useContext } from "react";
import { SortableListRecord } from "./SortableList.types";

type SortableListContext<TRecord extends SortableListRecord> = {
  isSelectable: boolean;
  isSortable: boolean;
  selectedItems: TRecord[];
  toggleSelection: (item: TRecord) => void;
  moveItem: (sourceId: string, destinationId: string, place: "after" | "before") => void;
  name: string;
};

const context = createContext<SortableListContext<{ id: string }> | null>(null);

export const SortableListContextProvider = <TRecord extends SortableListRecord>({
  value,
  children,
}: PropsWithChildren<{ value: SortableListContext<TRecord> }>) => {
  // @ts-expect-error -- The context is not generically shaped.
  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useSortableListContext = <TRecord extends SortableListRecord>() => {
  const providedContext = useContext(context);

  if (providedContext === null) {
    throw new Error("useSortableListContext must be used within a SortableListContextProvider");
  }

  return providedContext as unknown as SortableListContext<TRecord>;
};
