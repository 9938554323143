import classNames from "classnames";
import { format } from "date-fns";
import { ComponentProps, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Clickable } from "../Clickable";
import { Dropdown } from "../Dropdown";
import { Popover } from "../Popover";
import { DatePickerHeader } from "./DatePickerHeader";
import styles from "./DateRangePicker.module.scss";
import { DateRange } from "./DateRangePicker.types";
import { useDisplayWindow } from "./useDisplayWindow";
import { useDropdownState } from "./useDropdownState";
import { useRangeHighlight } from "./useRangeHighlight";

const dateFormat = "dd MMM yyyy";

type DateRangePickerProps = Pick<ComponentProps<typeof DatePicker>, "maxDate" | "minDate"> & {
  value?: DateRange;
  onValueChange?: (value: DateRange) => void;
  testId?: string;
  placeholder?: string;
  size?: "normal" | "small";
  label?: ReactNode;
  hasInlineLabel?: boolean;
};

// eslint-disable-next-line complexity -- todo: refactor
export const DateRangePicker = ({
  onValueChange,
  testId,
  value,
  placeholder,
  size = "normal",
  label,
  hasInlineLabel = false,
  ...datePickerProps
}: DateRangePickerProps) => {
  const { dropdownProps, openButtonProps, popoverProps, focusTrapRef, closeDropdown } = useDropdownState();
  const [newDateRange, setNewDateRange] = useState<DateRange | undefined>(undefined);
  const [start, end] = useMemo(() => {
    if (newDateRange) {
      return [newDateRange[0] ?? undefined, newDateRange[1] ?? undefined];
    }

    const start = value?.at(0) ? new Date(value.at(0)!) : undefined;
    const end = value?.at(1) ? new Date(value.at(1)!) : undefined;

    return [start, end];
  }, [newDateRange, value]);
  const { displayMonthEnd, displayMonthStart, handleEndMonthChange, handleStartMonthChange } = useDisplayWindow([
    start,
    end,
  ]);
  const { highlightRange, setHoveredEndDate, clearHoveredEndDate } = useRangeHighlight([start, end]);

  const handleStartChange = useCallback(
    ([newStart, newEnd]: DateRange) => {
      setNewDateRange([newStart, newEnd]);
      if (newStart && newEnd) {
        closeDropdown();
      }
    },
    [closeDropdown],
  );

  const handleEndChange = useCallback(
    (date: Date | null) => {
      setNewDateRange([start, date]);
      closeDropdown();
    },
    [closeDropdown, start],
  );

  useEffect(() => {
    if (newDateRange?.at(0) && newDateRange?.at(1)) {
      onValueChange?.(newDateRange);
      setNewDateRange(undefined);
    }
  }, [newDateRange, onValueChange]);

  const hasValue = !!value?.at(0) && !!value.at(1);
  const formatted = hasValue
    ? `${format(value!.at(0)!, dateFormat)} - ${format(value!.at(1)!, dateFormat)}`
    : placeholder;

  return (
    <div className={classNames(styles.root, size === "small" && styles.small)} data-testid={testId}>
      {!hasInlineLabel && label && <label className={styles.label}>{label}</label>}
      <Clickable
        className={styles.button}
        testId={testId && `${testId}-button`}
        data-active={hasValue}
        {...openButtonProps}
      >
        {hasInlineLabel ? (
          label
        ) : (
          <span className={styles.formattedLabel} title={formatted}>
            {formatted}
          </span>
        )}
      </Clickable>
      <Popover {...popoverProps} align="start" className={styles.popoverContainer}>
        <Dropdown hasPadding width="auto" height="auto" opensTo="right" {...dropdownProps}>
          <div className={styles.dropdownContainer} data-testid={testId}>
            <div className={styles.calendarContainer} data-testid={testId && `${testId}-start-date`}>
              <DatePicker
                {...datePickerProps}
                renderCustomHeader={(props) => (
                  <DatePickerHeader {...props} testId={testId && `${testId}-start-date`} focusableRef={focusTrapRef} />
                )}
                selected={start}
                showWeekNumbers
                startDate={start}
                endDate={end}
                selectsRange
                onChange={handleStartChange}
                openToDate={displayMonthStart}
                onMonthChange={handleStartMonthChange}
                inline
                highlightDates={highlightRange}
              />
            </div>
            <div
              className={styles.calendarContainer}
              data-testid={testId && `${testId}-end-date`}
              onMouseLeave={clearHoveredEndDate}
            >
              <DatePicker
                {...datePickerProps}
                renderCustomHeader={(props) => <DatePickerHeader {...props} testId={testId && `${testId}-end-date`} />}
                selected={end}
                showWeekNumbers
                startDate={start}
                endDate={end}
                selectsEnd
                onChange={handleEndChange}
                openToDate={displayMonthEnd}
                onMonthChange={handleEndMonthChange}
                onDayMouseEnter={setHoveredEndDate}
                inline
              />
            </div>
          </div>
        </Dropdown>
      </Popover>
    </div>
  );
};
