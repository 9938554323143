import classNames from "classnames";
import { CSSProperties, PropsWithChildren, RefObject, useEffect, useState } from "react";
import { createPortal } from "react-dom";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used in styles[position], styles[align]
import styles from "./Popover.module.scss";

type PopoverProps = {
  anchor: RefObject<HTMLElement>;
  className?: string;
  testId?: string;
  isVisible?: boolean;
  position?: "bottom" | "left" | "right" | "top";
  align?: "center" | "end" | "start";
};

export const Popover = ({
  children,
  anchor,
  className,
  testId,
  isVisible = true,
  position = "bottom",
  align = "end",
}: PropsWithChildren<PopoverProps>) => {
  const [rectStyle, setRectStyle] = useState<CSSProperties>({});

  useEffect(() => {
    if (!anchor.current) return;

    const rect = anchor.current.getBoundingClientRect();

    const scrollY = window.scrollY;
    const scrollX = window.scrollX;

    setRectStyle({
      "--anchor-left": `${rect.left + scrollX}px`,
      "--anchor-top": `${rect.top + scrollY}px`,
      "--anchor-width": `${rect.width}px`,
      "--anchor-height": `${rect.height}px`,
    } as CSSProperties);
  }, [anchor, isVisible]);

  return (rectStyle &&
    isVisible &&
    createPortal(
      <div
        data-testid={testId}
        style={{ ...rectStyle }}
        className={classNames(styles.popover, className, styles[position], styles[align])}
      >
        {children}
      </div>,
      // TODO: Adding all popover elements to the body is not ideal. SIM-37683
      document.body,
    )) as JSX.Element;
};
