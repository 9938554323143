import { skipToken, useGetV2SalesQuery } from "@simplicate/api-client";
import { PageHeader } from "@simplicate/top-bar";
import { useParams } from "react-router-dom";
import { RelationTag } from "../RelationTag";
import { SalesStatusTag } from "./SalesStatusTag";

export const SalesSubHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { data: sales } = useGetV2SalesQuery(/* istanbul ignore next */ id ?? skipToken);

  if (!sales?.data?.subject) return null;

  const subject = sales.data.subject;
  const relationName = sales.data.organization?.name ?? sales.data.person?.full_name;
  const statusId = sales.data.status?.id;
  const organizationId = sales.data.organization?.id;
  const personId = sales.data.person?.id;

  return (
    <PageHeader title={subject}>
      <RelationTag organizationId={organizationId} personId={personId} name={relationName} />
      <SalesStatusTag statusId={statusId} />
    </PageHeader>
  );
};
