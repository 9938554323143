/* c8 ignore start */
/* istanbul ignore file */

import { createRoot } from "react-dom/client";
import { App } from "./App";

const rootEl = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-unused-expressions -- expression is used to render the app
rootEl && createRoot(rootEl).render(<App />);
/* c8 ignore end */
