import { useCallback } from "react";
import { SortableListContextProvider } from "./SortableList.context";
// eslint-disable-next-line css-modules/no-unused-class -- classes are used
import styles from "./SortableList.module.scss";
import { SortableListRecord } from "./SortableList.types";
import { SortableListItem } from "./SortableListItem";

type ListProps<TRecord extends SortableListRecord> = {
  children: (item: TRecord) => JSX.Element;
  data: TRecord[];
  isSelectable?: boolean;
  isSortable?: boolean;

  /**
   * Name of all the checkboxes in the list, for form support. Ensure the provided name includes the
   * brackets to indicate this component controls a collection of things.
   *
   * @example
   * ```tsx
   * <SortableList name="items[]">...</SortableList>
   * ```
   */
  name?: string;
  onOrderChange?: (newOrder: TRecord[]) => void;
  onSelectionChange?: (selectedItems: TRecord[]) => void;
  selection?: TRecord[];
  testId?: string;
};

export const SortableList = <TRecord extends SortableListRecord>({
  testId,
  name = "",
  data,
  selection = [],
  isSelectable = false,
  isSortable = false,
  onSelectionChange,
  onOrderChange,
  children: renderItem,
}: ListProps<TRecord>) => {
  const handleToggleSelection = useCallback(
    (item: TRecord) => {
      if (selection.find(({ id }) => item.id === id) !== undefined) {
        onSelectionChange?.(selection.filter(({ id }) => item.id !== id));
      } else {
        onSelectionChange?.([...selection, item]);
      }
    },
    [onSelectionChange, selection],
  );

  const handleMoveItem = useCallback(
    (sourceId: string, destinationId: string, place: "after" | "before") => {
      if (sourceId === destinationId) {
        return;
      }

      const sourceItem = data.find(({ id }) => id === sourceId);
      const newItems = [...data].filter(({ id }) => id !== sourceId);
      const destinationIndex = newItems.findIndex(({ id }) => id === destinationId);

      if (place === "after") {
        newItems.splice(destinationIndex + 1, 0, sourceItem!);
      } else {
        newItems.splice(destinationIndex, 0, sourceItem!);
      }

      onOrderChange?.(newItems);
    },
    [onOrderChange, data],
  );

  return (
    <SortableListContextProvider
      value={{
        isSelectable,
        isSortable,
        selectedItems: selection,
        toggleSelection: handleToggleSelection,
        moveItem: handleMoveItem,
        name,
      }}
    >
      <ul className={styles.list} data-testid={testId}>
        {data.map((item) => (
          <SortableListItem key={item.id} renderItem={renderItem} value={item} />
        ))}
      </ul>
    </SortableListContextProvider>
  );
};
