import { combineSlices } from "@reduxjs/toolkit";
import { HeaderState, headerReducer } from "./headerSlice";
import { IframeState, iFrameReducer } from "./iFrameSlice";
import { LocaleState, localeReducer } from "./localeSlice";
import { MainMenuState, mainMenuReducer } from "./mainMenuSlice";
import { planhatReducer, PlanhatState } from "./planhatSlice";

export type PredefinedSlices = {
  header: HeaderState;
  iframe: IframeState;
  locale: LocaleState;
  mainMenu: MainMenuState;
  planhat: PlanhatState;
};
export type RootState<TAdditionalSlices extends object = Record<string, never>> = {
  state: PredefinedSlices & TAdditionalSlices;
};

export const rootReducer = combineSlices({
  header: headerReducer,
  iframe: iFrameReducer,
  locale: localeReducer,
  mainMenu: mainMenuReducer,
  planhat: planhatReducer,
});

export { setHeader } from "./headerSlice";
export {
  type IframeEvent,
  type PostIFrameMessage,
  type IframeActionPayload,
  iframeMessageReceived,
  setPostIframeMessageHandler,
  postIframeMessage,
  clearMessages,
} from "./iFrameSlice";
export { type Locale, setLocale } from "./localeSlice";
export { toggleMainMenuExpanded } from "./mainMenuSlice";
export { logLocation } from "./planhatSlice";
