/* istanbul ignore file -- IntersectionObserver does not exist in jest, nor does jsdom actually do any layouting. */
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useIntersectionObserver, IntersectionObserverOptions } from "../../../hooks";

export type StickyHeaderOptions = Pick<IntersectionObserverOptions, "margin"> & { enabled: boolean };

export const useStickyHeader = (
  tableContainerRef: RefObject<HTMLDivElement>,
  { enabled, margin }: StickyHeaderOptions,
) => {
  const [width, setWidth] = useState<string>("");

  useEffect(() => {
    const currentTableContainer = tableContainerRef.current;

    if (!enabled || !currentTableContainer) return;

    const updateWidth = () => {
      setWidth(currentTableContainer.getBoundingClientRect().width + "px");
    };

    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    resizeObserver.observe(currentTableContainer);

    updateWidth();

    return () => {
      resizeObserver.unobserve(currentTableContainer);
    };
  }, [enabled, tableContainerRef]);

  const theadElement = useRef<HTMLTableSectionElement | null>(null);

  const tableObserver = useIntersectionObserver(tableContainerRef.current, { margin });
  const tableHeadObserver = useIntersectionObserver(theadElement.current, { margin });

  useEffect(() => {
    const updateThead = () => {
      const thead = tableContainerRef.current?.getElementsByTagName("thead")[0];

      if (thead) {
        theadElement.current = thead;
      }
    };

    if (!theadElement.current) {
      updateThead();
    }
  }, [tableContainerRef]);

  const visible = useMemo(() => {
    if (!enabled) return;

    const tableInView = tableObserver.inView;
    const theadInView = tableHeadObserver.inView;

    return !theadInView && tableInView;
  }, [enabled, tableHeadObserver.inView, tableObserver.inView]);

  return { visible, width };
};
