import { useMemo } from "react";
import { Setting, SettingKey, SettingModule, SettingValue, useGetSettingsQuery } from "../../endpoints";

type Result<TModule extends SettingModule, TKey extends SettingKey<TModule>> = {
  key: TKey;
  module: TModule;
  options?: number[] | string[];
  value: SettingValue<TModule, TKey>;
};

export function parseSettingValue<TModule extends SettingModule>({
  type,
  value_type,
  value,
}: Setting<TModule>): SettingValue<TModule, SettingKey<TModule>> {
  const valueType = value_type ?? type;

  let parsedValue;

  if (valueType === "string") {
    parsedValue = value;
  } else if (valueType === "int") {
    parsedValue = parseInt(String(value));
  } else if (valueType === "float") {
    parsedValue = parseFloat(String(value));
  } else {
    parsedValue = value;
  }

  return parsedValue as SettingValue<TModule, SettingKey<TModule>>;
}

export const useEnvironmentSetting = <TModule extends SettingModule, TKey extends SettingKey<TModule>>(
  module: TModule,
  key: TKey,
): Result<TModule, TKey> | undefined => {
  const { data } = useGetSettingsQuery();

  return useMemo(() => {
    if (!data?.data) {
      return undefined;
    }

    const setting = data.data
      .find((category) => category.module === module)
      ?.settings?.find((setting) => setting.key === key);

    if (!setting) {
      return undefined;
    }

    return {
      key,
      module,
      value: parseSettingValue<TModule>(setting),
      options: setting.values,
    } as Result<TModule, TKey>;
  }, [data, module, key]);
};
