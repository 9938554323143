// istanbul ignore file -- requestIdleCallback is not supported in all browsers, nor does it exist in jsdom.
import { useEffect } from "react";
import { preloadLegacyWebComponent } from "../components";

export const usePreloadLazyComponents = () => {
  useEffect(() => {
    if (!("requestIdleCallback" in window)) {
      // If the browser does not support requestIdleCallback, we can't preload at a safe time, so don't.
      // Safari does not support requestIdleCallback, so we can't use it.
      return;
    }

    const callback = window.requestIdleCallback(() => {
      preloadLegacyWebComponent();
    });

    return () => {
      window.cancelIdleCallback(callback);
    };
  }, []);
};
