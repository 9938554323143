// istanbul ignore file -- This is a lazy loading setup, testing this would mean testing our dependencies
import { Page, Spinner } from "@simplicate/ui";
import { Suspense } from "react";
import { lazyWithPreload } from "react-lazy-with-preload";

const Component = lazyWithPreload(() => import("./LegacyWebComponent"));

export const preloadLegacyWebComponent = () => void Component.preload();

export const LegacyWebComponent = () => {
  return (
    <Suspense
      fallback={
        <Page>
          <Spinner size="large" />
        </Page>
      }
    >
      <Component />
    </Suspense>
  );
};
