import classnames from "classnames";
import { FocusEventHandler, PropsWithChildren, useEffect, useRef, FocusEvent } from "react";
import { Triangle } from "../Triangle";
import styles from "./Dropdown.module.scss";

type DropdownProps = PropsWithChildren<{
  height?: "auto" | "limitedWithScroll";
  hasPadding?: boolean;
  testId?: string;
  title?: string;
  variant?: "clean" | "withIndicator";
  opensTo?: "left" | "right";
  width?: "auto" | "default" | "small";
  isOpen?: boolean;
  onClickOutside?: (event: MouseEvent) => void;
  onKeyupOutside?: (event: KeyboardEvent) => void;
  onKeyboardDismiss?: (event: KeyboardEvent) => void;
  onFocusLoss?: FocusEventHandler;
  onClose?: (event: MouseEvent) => void;
}>;

const widthClassMapping = {
  auto: null,
  small: styles.widthSmall,
  default: styles.widthDefault,
};

const heightClassMapping = { auto: null, limitedWithScroll: styles.heightDefault };

export const Dropdown = ({
  children,
  height = "auto",
  hasPadding = false,
  testId,
  title,
  variant = "withIndicator",
  width = "default",
  isOpen = false,
  opensTo = "left",
  onClickOutside,
  onKeyupOutside,
  onKeyboardDismiss,
  onFocusLoss,
}: DropdownProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  const handleBlur = (event: FocusEvent) => {
    if (divRef.current && !divRef.current.contains(event.relatedTarget as Node)) {
      onFocusLoss?.(event);
    }
  };

  useEffect(() => {
    const isOutsideDropdown = (target: Node) =>
      divRef.current && target.isConnected && !divRef.current.contains(target);

    const handleClickOutside = (event: MouseEvent) => {
      if (isOutsideDropdown(event.target as Node)) {
        onClickOutside?.(event);
      }
    };

    const handleKeyup = (event: KeyboardEvent) => {
      if (event.key === "Tab" && isOutsideDropdown(event.target as Node)) {
        onKeyupOutside?.(event);
      } else if (event.key === "Escape") {
        onKeyboardDismiss?.(event);
      }
    };

    if (isOpen) {
      setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("keyup", handleKeyup);
      });

      return () => {
        document.removeEventListener("click", handleClickOutside);
        document.removeEventListener("keyup", handleKeyup);
      };
    }
  }, [isOpen, onClickOutside, onKeyboardDismiss, onKeyupOutside]);

  return (
    isOpen && (
      <div
        className={classnames(styles.dropdown, widthClassMapping[width])}
        data-testid={testId}
        ref={divRef}
        onBlurCapture={handleBlur}
      >
        {variant === "withIndicator" && (
          <div className={classnames(styles.dropdownTriangle, opensTo === "right" && styles.alignLeft)}>
            <Triangle />
          </div>
        )}
        <div
          className={classnames(styles.content, hasPadding && styles.withPadding, heightClassMapping[height])}
          data-testid={`${testId}-content`}
        >
          <div className={styles.scrollContainer}>
            {title && <h5 className={styles.title}>{title}</h5>}
            {children}
          </div>
        </div>
      </div>
    )
  );
};
