import { RefObject } from "react";
import { createPortal } from "react-dom";

type TargetType = Parameters<typeof createPortal>[1];

export const Portal = ({
  targetRef,
  children,
}: {
  targetRef?: RefObject<TargetType | undefined>;
  children?: React.ReactNode;
}) => {
  if (!targetRef?.current) return null;

  return createPortal(children, targetRef.current);
};
