import { useTranslation } from "@simplicate/translations";
import { Button, Icon, Input, Modal, TextArea } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import { ChangeEventHandler } from "react";
import styles from "./ServiceGroupModal.module.scss";

type ServiceGroupModalProps = {
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isOpen: boolean;
  isEditModal?: boolean;
  name: string;
  description?: string;
  hasError?: boolean;
  onDescriptionChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onNameChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export const ServiceGroupModal = ({
  onClose,
  isOpen,
  onSubmit,
  isEditModal,
  name,
  description,
  hasError,
  onDescriptionChange,
  onNameChange,
}: ServiceGroupModalProps) => {
  const { t } = useTranslation("sales_services");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form className={styles.modalContent} onSubmit={onSubmit}>
        <h3>{isEditModal ? t("service_group.edit_title") : t("service_group.add_title")}</h3>
        <Input
          type="text"
          name="name"
          label={t("service_group.name_label")}
          onChange={onNameChange}
          value={name}
          hasError={hasError}
        />
        {hasError && (
          <div className={styles.error}>
            <span className={styles.errorIcon}>
              <Icon fixedWidth icon="exclamationTriangle" color={lightColorIconError} />
            </span>
            <div>{t("validation.required")}</div>
          </div>
        )}
        <TextArea
          name="description"
          label={t("service_group.description_label")}
          label_tooltip={t("service_group.description_label_tooltip")}
          onChange={onDescriptionChange}
          value={description}
        />
        <div className={styles.modalButtonsWrapper}>
          <Button variant="primary" size="medium" type="submit">
            {isEditModal ? t("button_save") : t("button_add")}
          </Button>
          <Button variant="subtle" size="medium" onClick={onClose}>
            {t("button_cancel")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
