import {
  PropsWithChildren,
  SyntheticEvent,
  useRef,
  forwardRef,
  useImperativeHandle,
  KeyboardEvent,
  useState,
  ForwardedRef,
} from "react";
import styles from "./Dialog.module.scss";

type DialogProps = PropsWithChildren & {
  testId?: string;
};

export type DialogResult<FormValues> =
  | {
      status: "submit";
      data?: FormValues;
    }
  | { status: "cancel"; data?: never };

/* istanbul ignore next */
export const useDialogRef = <FormValues,>() => useRef<DialogHandle<FormValues>>(null);

export interface DialogHandle<FormValues> {
  open: () => Promise<DialogResult<FormValues>>;
}

export const Dialog = forwardRef(function Dialog<FormValues>(
  { children, testId }: DialogProps,
  ref: ForwardedRef<unknown>,
) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const [promise, setPromise] = useState<{
    resolve: (value: DialogResult<FormValues> | PromiseLike<DialogResult<FormValues>>) => void;
    reject: (reason?: null) => void;
  } | null>(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      dialogRef.current?.showModal();

      return new Promise<DialogResult<FormValues>>((resolve, reject) => {
        setPromise({ resolve, reject });
      });
    },
  }));

  const handleKeyUp = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Escape") handleCancel();
  };

  const handleCancel = () => {
    dialogRef.current?.close();

    promise?.resolve({ status: "cancel" });
  };

  const handleSubmit = (event: SyntheticEvent<HTMLDialogElement, Event>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);

    /* istanbul ignore else */
    if (dialogRef.current) {
      dialogRef.current.close();
    }

    promise?.resolve({ data: Object.fromEntries(formData.entries()) as FormValues, status: "submit" });
  };

  const handleClick = (event: SyntheticEvent<HTMLDialogElement, Event>) => {
    /* istanbul ignore next */
    if (event.target === event.currentTarget) handleCancel();
  };

  return (
    /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions -- Dialog handles the key events */
    <dialog
      className={styles.dialog}
      data-testid={testId}
      ref={dialogRef}
      onKeyUp={handleKeyUp}
      onCancel={handleCancel}
      onReset={handleCancel}
      onSubmit={handleSubmit}
      onClick={handleClick}
    >
      {children}
    </dialog>
  );
});
